/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef, useState } from 'react'
import { TextInput } from '@mantine/core'
import * as styles from '../ReactTable.module.scss'
import { IconSearch } from '@tabler/icons-react'
import PropTypes from 'prop-types'

export default function SearchBar ({ value, onChange }) {
  const [searchString, setSearchString] = useState(value)
  const searchStringRef = useRef(searchString)

  useEffect(() => {
    if (searchStringRef.current !== searchString) {
      const timer = setTimeout(() => {
        searchStringRef.current = searchString
        onChange(searchString)
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [searchString])

  useEffect(() => {
    if (value !== searchStringRef.current) {
      searchStringRef.current = value
      setSearchString(value)
    }
  }, [value])

  return (
    <TextInput
      value={searchString ?? ''}
      placeholder='Search'
      onChange={ev => { setSearchString(ev.target.value) }}
      rightSection={<IconSearch size='1rem' className={value !== searchString ? styles.waiting : null} />}
    />
  )
}

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}
