import React from 'react'
import Content from '../../layout/Content'
import CycleManagementDetail from './CycleManagementDetail'

/**
 * Wrapper component for {@link CycleManagementDetail} to be able to accept parameters from the root div container.
 * @returns {React.JSX.Element}
 */
export default function CycleManagementDetailWrapper () {
  const selected = document.getElementById('cycle-management-detail-container').getAttribute('data-selected')
  const id = document.getElementById('cycle-management-detail-container').getAttribute('data-cycle-id')

  return <Content><CycleManagementDetail id={id} selected={selected}/></Content>
}
