/* eslint react/prop-types: 0 */
import React, { useState } from 'react'
import { Anchor, Button, Grid, Group, PasswordInput, SimpleGrid, TextInput, Title } from '@mantine/core'
import * as styles from './AccountTabs.module.scss'
import { useForm } from '@mantine/form'
import PasswordStrength from '../core/PasswordStrength'
import { updatePassword } from '../../js/api/account_repository'
import { showNotification } from '@mantine/notifications'
import { useDisclosure } from '@mantine/hooks';

export default function UpdatePassword ({ account, apiBasePath }) {
  const [update, setUpdate] = useState(false)
  const [visible, { toggle }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: ''
    },
    validate: {
      current_password: value => value === '' ? 'Current password cannot be empty' : null,
      new_password: value => value === '' ? 'New password cannot be empty' : null,
      confirm_password: (value, values) => value !== values.new_password ? 'Passwords did not match' : null
    }
  })

  const submit = data => {
    updatePassword(apiBasePath, data)
      .then(() => {
        showNotification({
          message: 'Your account was successfully updated!',
          color: 'success',
          autoClose: 3000
        })
      })
      .then(() => form.reset())
      .catch(err => {
        if (err.response?.data?.type === 'validation_error') {
          for (const message of err.response.data.errors) {
            showNotification({
              title: 'Validation error',
              message: message,
              color: 'red'
            })
          }
        } else {
          showNotification({
            title: 'Something went wrong',
            message: 'There was an error updating your account',
            color: 'red'
          })
        }
      })
  }

  return (
    <Grid className={styles.grid}>
      <Grid.Col span={2}>
        <Title order={4}>Password</Title>
      </Grid.Col>
      <Grid.Col span={4}>
        {!update && <Button onClick={() => setUpdate(true)}>Update Password</Button>}
        {update &&
          <form onSubmit={form.onSubmit(values => submit(values))}>
            <SimpleGrid cols={1} verticalSpacing='xl'>
              <TextInput type='password' label='Current password' aria-label='Current password' {...form.getInputProps('current_password')} ></TextInput>
              <PasswordInput
                label='New password'
                aria-label='New password'
                visible={visible}
                onVisibilityChange={toggle}
                {...form.getInputProps('new_password')}
              />
              <PasswordInput
                label='Password confirmation'
                aria-label='Password confirmation'
                visible={visible}
                onVisibilityChange={toggle}
                {...form.getInputProps('confirm_password')}
              />
              <PasswordStrength password={form.values.new_password} options={[account.first_name, account.last_name, account.email]}/>
              <Group>
                <Button type='submit' color='success'>Save changes</Button>
                <Anchor href='/recover-password' fz='md'>I forgot my password</Anchor>
              </Group>
            </SimpleGrid>
          </form>
        }
      </Grid.Col>
    </Grid>
  )
}
