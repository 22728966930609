import React from 'react'
import { Skeleton, Tabs } from '@mantine/core'
import {
  IconClipboardText,
  IconEdit,
  IconExposurePlus1,
  IconSettingsAutomation,
  IconTool,
  IconTrash
} from '@tabler/icons-react'
import { useGetHiringTemplateQuery } from '../../../redux/query/build/hiringTemplatesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import HiringTemplateListDisplay from './HiringTemplateListDisplay'

/**
 * Component that displays information about a hiring template and tabs that link to different management pages.
 * @param {object} props
 * @param {string|number} props.id The id of the hiring template.
 * @param {string} props.selected The tab that should be highlighted by default.
 * @returns {React.JSX.Element}
 */
function HiringTemplateDetail ({ id, selected }) {
  const { data: hiringTemplate, error, isLoading } = useGetHiringTemplateQuery(id)

  const tabs = {
    edit: `build/hiring-templates/${id}/edit`,
    specs: `build/hiring-templates/${id}/specs`,
    batteries: `build/hiring-templates/${id}/batteries`,
    triggers: `build/hiring-templates/${id}/triggers`,
    create: `build/hiring-templates/${id}/createCycle`,
    delete: `build/hiring-templates/${id}/delete`
  }

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <>
      <Skeleton height={25} width={150} mb='sm' />
      <Skeleton height={25} width={400} mb='xs'/>
      <Skeleton height={25} width={400} mb='md' />
      <Skeleton height={25} mb='lg' />
    </>
  }

  return (
    <>
      <HiringTemplateListDisplay hiringTemplate={hiringTemplate} />
      <Tabs
        radius='md'
        variant='default'
        mb='xl'
        value={tabs[selected]}
        /* eslint-disable-next-line no-return-assign */
        onChange={value => window.location.href = value}
      >
        <Tabs.List>
          <Tabs.Tab value={tabs.edit} color='yellow.6' leftSection={<IconEdit size='1rem'/>}>Edit</Tabs.Tab>
          <Tabs.Tab value={tabs.specs} color='green.6' leftSection={<IconTool size='1rem'/>}>Specs</Tabs.Tab>
          <Tabs.Tab value={tabs.batteries} color='blue.6' leftSection={<IconClipboardText size='1rem'/>}>Batteries</Tabs.Tab>
          <Tabs.Tab value={tabs.triggers} color='violet.6' leftSection={<IconSettingsAutomation size='1rem'/>}>Triggers</Tabs.Tab>
          <Tabs.Tab value={tabs.create} color='pink.6' leftSection={<IconExposurePlus1 size='1rem'/>}>Create Cycle</Tabs.Tab>
          <Tabs.Tab value={tabs.delete} color='red.6' leftSection={<IconTrash size='1rem'/>}>Delete</Tabs.Tab>
        </Tabs.List>
      </Tabs>
    </>
  )
}

export default React.memo(HiringTemplateDetail)
