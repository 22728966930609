/* eslint react/prop-types: 0 */
import { useSelector } from 'react-redux';
import { selectParamsFilterField } from '../../core/ReactTable/paramsSlice';
import {
  Anchor,
  ScrollArea,
  Text,
  Flex
} from '@mantine/core';
import React, { useMemo } from 'react';
import { useHover } from '@mantine/hooks';

export function DetailViewScoreCell ({ value, moduleInfoType, moduleId, precision, namespace, applicantLink, moduleMinScore, mah }) {
  const display = useSelector(state => selectParamsFilterField(state, namespace, 'display'))
  const score = getScoreForDisplayType(value, moduleInfoType, moduleId, display ?? 'percentile')
  const [formattedScore, parsedScore] = formatScoreDisplay(score, precision ?? 0)
  const color = (parsedScore === null) ? 'dark' : (parsedScore < moduleMinScore ? 'red' : 'blue')

  const { hovered, ref } = useHover()

  const props = useMemo(() => {
    const defaultStyles = {
      borderRadius: '0.75rem',
      transition: 'transform 250ms ease-out'
    }

    const hoveredProps = hovered
      ? {
          bg: `${color}.1`,
          p: 'sm',
          fw: 'bold',
          style: {
            ...defaultStyles,
            filter: 'brightness(98%)',
            transform: 'scale(1.25)'
          }
        }
      : {}

    return {
      p: 'xs',
      c: `${color}.6`,
      style: {
        ...defaultStyles,
        borderRadius: '1rem'
      },
      ...hoveredProps
    }
  }, [hovered, color])

  return (
    <Flex justify='center' align='center' h={mah}>
      {(applicantLink && (formattedScore !== '-'))
        ? (
            <Anchor ref={ref} href={applicantLink} target='_blank' fw='500' {...props}>
              {formattedScore}
            </Anchor>
          )
        : (
          <ScrollArea.Autosize mah={mah}>
            <Text ta='center' fw='500' c={color === 'blue' ? 'dark.3' : color}>
              {formattedScore}
            </Text>
          </ScrollArea.Autosize>
          )}
    </Flex>
  )
}

export function formatScoreDisplay (score, maximumDigits = 0) {
  const parsedScore = (score !== '' && score !== '-' && score !== null && score !== undefined) ? parseFloat(score) : null
  const parsedScoreValid = parsedScore !== null && !isNaN(parsedScore)
  const formattedScore = parsedScoreValid ? parsedScore.toLocaleString('en-US', { maximumFractionDigits: maximumDigits }) : score
  return [formattedScore ?? '-', parsedScoreValid ? parsedScore : null]
}

const MODULE_INFO_TYPE_NO_CAST_MAGIC_NUMBER = 2
const DEFAULT_PERCENTILE_MAGIC_MODULE_INFO_TYPE_IDS = [3, 4]
const DEFAULT_PERCENTILE_MAGIC_MODULE_IDS = [14, 33, 53, 54, 55, 56]

function getScoreForDisplayType (score, moduleInfoTypeId, moduleId, display) {
  switch (display) {
    case 'zscore': {
      return MODULE_INFO_TYPE_NO_CAST_MAGIC_NUMBER !== moduleInfoTypeId ? score.z_score : score.score
    }
    case 'percentile': {
      return MODULE_INFO_TYPE_NO_CAST_MAGIC_NUMBER !== moduleInfoTypeId ? formatPercentileScore(score.percentile_score) : score.score
    }
    default: {
      if (DEFAULT_PERCENTILE_MAGIC_MODULE_INFO_TYPE_IDS.includes(moduleInfoTypeId) || DEFAULT_PERCENTILE_MAGIC_MODULE_IDS.includes(moduleId)) {
        return formatPercentileScore(score.percentile_score)
      }
      return score.score
    }
  }
}

function formatPercentileScore (percentileScore) {
  if (percentileScore > 99) {
    return 99
  } else if (percentileScore < 1) {
    return 1
  }

  return percentileScore
}
