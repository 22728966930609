/* eslint react/prop-types: 0 */
import React, { useContext, useState, useRef, useMemo, useEffect, useCallback } from 'react'
import { useCycleStatuses } from './CycleHooks'
import { useDisclosure } from '@mantine/hooks'
import { CycleContext } from '../../core/ReactTable/TableContexts'
import { useEditStatusMutation } from '../../../redux/query/hire/applicantsApi.slice'
import { Box, Button, Center, Flex, Group, Modal, Select, Space } from '@mantine/core'
import NoticeAlert from '../../core/Alert/NoticeAlert'
import { DatePicker } from '@mantine/dates'
import dayjs from 'dayjs'

export function DetailViewStatusCell ({ cell: { value: row } }) {
  const value = row.status?.name ?? ''
  const cycleId = useContext(CycleContext)
  // const { cycleId } = useParams()  // useParams is causing a rerender on params change even when cycleId does not change - resolved with context provider.
  const [statuses] = useCycleStatuses(cycleId)
  const [opened, { open, close }] = useDisclosure(false)
  const [dateModalOpened, { open: openDateModal, close: closeDateModal }] = useDisclosure(false)
  const [currentValue, setCurrentValue] = useState(value)
  const ignoreClickRef = useRef(false)
  const initializedRef = useRef(false)
  const [edit] = useEditStatusMutation()

  const statusOptions = useMemo(() => {
    if (!statuses?.items?.length) {
      return noStatusOptions
    }
    return statuses.items.map(status => ({ value: status.name, label: status.name, meta: status }))
  }, [statuses])

  const applicantId = row.id

  const onSelect = useCallback((newValue, selectedOption) => {
    console.debug('Called on status select', { newValue, selectedOption, currentValue, applicantId })
    if (newValue) {
      if (newValue !== currentValue) {
        setCurrentValue(newValue)
        if (selectedOption?.meta?.tier?.id === hiredTierId) {
          openDateModal()
        } else {
          edit({ status: newValue, applicants: [applicantId], hiredDate: '', cycleId: cycleId })
        }
      }
    }
    ignoreClickRef.current = true
    close()
  }, [currentValue, applicantId, cycleId, openDateModal, close, edit])

  const onModalClose = useCallback((fromInsideClick = true) => {
    console.debug('Called status modal close', { value, fromInsideClick })
    closeDateModal()
    setCurrentValue(value)
    if (!fromInsideClick) {
      ignoreClickRef.current = true
    }
  }, [value, closeDateModal])

  const onModalSave = useCallback((hiredDate) => {
    console.debug('Called status modal save', { hiredDate, currentValue, applicantId })
    edit({ status: currentValue, applicants: [applicantId], hiredDate: hiredDate, cycleId: cycleId })
    closeDateModal()
  }, [currentValue, applicantId, cycleId, closeDateModal, edit])

  const onBoxClick = (event) => {
    console.debug('Called status box on click', { event, opened, dateModalOpened })
    event.stopPropagation()
    const ignoreClick = ignoreClickRef.current
    if (!dateModalOpened && !ignoreClick) {
      open()
    }
    ignoreClickRef.current = false
  }

  useEffect(() => {
    if (initializedRef.current) {
      setCurrentValue(value)
      close()
      closeDateModal()
      ignoreClickRef.current = false
    } else {
      initializedRef.current = true
    }
  }, [value, close, closeDateModal])

  console.debug('Detail status cell updating', { currentValue, value, applicantId, row, opened, statusOptions })

  return (
    <Flex onBlur={close} align='center' justify='center' pos='relative'>
      {!(statuses && opened) && (<Button color='dark.3' variant='subtle' fw={500} onClick={onBoxClick}>{currentValue}</Button>)}
      {(statuses && opened) && (<Select data={statusOptions} pos='absolute' value={currentValue} onBlur={() => close()} onChange={onSelect} onClick={(e) => e.stopPropagation()} role='combobox' />)}
      <StatusCellHiredDateModal opened={dateModalOpened} onClose={onModalClose} onSave={onModalSave} />
    </Flex>
  )
}

const noStatusOptions = []
const hiredTierId = 2

function StatusCellHiredDateModal ({ opened, onClose, onSave }) {
  const [value, setValue] = useState(new Date())

  const onClick = () => {
    onSave(dayjs(value).local().format('YYYY-MM-DD'))
  }
  console.debug('StatusCellHiredDateModal updating', { value, opened })
  return (
    <Modal opened={opened} onClose={() => onClose(false)} title='Info Required!'>
      <Box onClick={(event) => { event.stopPropagation() }}>
        <NoticeAlert title='Please select the hire date' />
        <Center><DatePicker value={value} onChange={setValue} /></Center>
        <Space h='md' />
        <Group justify='center' grow>
          <Button onClick={onClick} color='success'>Save</Button>
          <Button onClick={onClose} color='gray'>Cancel</Button>
        </Group>
      </Box>
    </Modal>
  )
}
