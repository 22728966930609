import React from 'react'
import BatteryDependencies from './BatteryDependencies'
import Base from '../../layout/Base'

/**
 * This component wraps the {@link BatteryDependencies} component to allow to pass props from the render div container.
 * @returns {React.JSX.Element}
 */
export default function BatteryDependenciesWrapper () {
  const id = parseInt(document.getElementById('battery-dependencies-container').getAttribute('data-id'))

  return <Base><BatteryDependencies id={id} /></Base>
}
