import React from 'react'
import dayjs from 'dayjs'
import * as styles from './Footer.module.scss'

export default function Footer () {
  return (
    <div className={styles.footer}>
      <span><i className='fa fa-copyright'/> { dayjs().format('YYYY') } Stang Decision Systems</span>
    </div>
  )
}
