import React from 'react'
import CycleManagementDetail from './CycleManagementDetail'
import { useParams } from 'react-router-dom'

/**
 * This component allows to use the {@link CycleManagementDetail} component using the URI to find the ID.
 * @param {object} props
 * @param {string} props.selected The tab that should be highlighted by default.
 * @returns {React.JSX.Element}
 */
export default function CycleManagementDetailParams ({ selected }) {
  const { id } = useParams()

  return <CycleManagementDetail id={id} selected={selected}/>
}
