import React from 'react'
import BatteryManagement from '../batteries/BatteryManagement'
import { useParams } from 'react-router-dom'
import {
  useAddCycleBatteryMutation,
  useDeleteCycleBatteryMutation,
  useEditCycleBatteryMutation,
  useGetCycleQuery,
  useToggleCycleBatteryMutation
} from '../../../redux/query/hire/cyclesApi.slice'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import _ from 'lodash'
import { showNotification } from '@mantine/notifications'
import { downloadFile } from '../../../services/public.service'
import { format } from '../../../util/date.util'

/**
 * Component that allows  to manage cycle batteries.
 * @returns {React.JSX.Element}
 */
export default function CycleBatteryManagement () {
  const { id } = useParams()

  const { data: cycle, error, isLoading } = useGetCycleQuery(id)

  const [addBattery] = useAddCycleBatteryMutation()
  const [editBattery] = useEditCycleBatteryMutation()
  const [deleteBattery] = useDeleteCycleBatteryMutation()
  const [toggleBattery] = useToggleCycleBatteryMutation()

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  const exportBattery = function (batteryId) {
    downloadFile(`/api/v1/cycles/${id}/batteries/${batteryId}/export`, `Cycle_Battery_Export_${format(new Date())}.xlsx`)
      .catch(err => {
        const data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)))

        if (err.response.status === 400 && data?.type === 'validation_error') {
          _.forEach(data.errors, error => {
            showNotification({
              title: 'Something went wrong',
              message: error,
              color: 'red',
              autoClose: 3000
            })
          })
        } else {
          showNotification({
            message: 'Something went wrong',
            color: 'red',
            autoClose: 3000
          })
        }
      })
  }

  return (
    <BatteryManagement
      linkedBatteries={cycle.cycle_batteries}
      triggers={cycle.triggers}
      addBattery={json => addBattery({ id, ...json }).unwrap()}
      editBattery={(batteryId, json) => editBattery({ id, batteryId, ...json }).unwrap()}
      deleteBattery={batteryId => deleteBattery({ id, batteryId }).unwrap()}
      toggleBattery={batteryId => toggleBattery({ id, batteryId }).unwrap()}
      reviewBattery={batteryId => window.open(`/proctorfree/cycles/${id}/batteries/${batteryId}/review`, '_blank')}
      exportBattery={batteryId => exportBattery(batteryId)}
    />
  )
}
