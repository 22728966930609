/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { DndContext, DragOverlay, pointerWithin, rectIntersection } from '@dnd-kit/core';
import DroppableList from './DroppableList';
import _ from 'lodash';
import DraggableItem from './DraggableItem';
import * as styles from './DragSelect.module.scss';
import { Tooltip, Button, Container, Flex, SimpleGrid } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { DragSelectUpdate } from './DragSelectState';

function DragSelect ({ lists, highlighted, state, dispatch }) {
  const [activeId, setActiveId] = useState(null);

  const clearHighlightedItems = listId => {
    dispatch({ type: DragSelectUpdate.RemoveHighlights, targetList: listId })
  }

  const highlightAll = listId => {
    dispatch({ type: DragSelectUpdate.HighlightList, targetList: listId })
  }

  const highlightItem = (item) => {
    dispatch({ type: DragSelectUpdate.ToggleHighlight, targetItem: item.id })
  }

  const handleDragStart = ({ active }) => {
    console.debug('Setting active id', active?.id)
    setActiveId(active.id)
  }

  const handleDragOver = ({ active, over }) => {
    if (over === null) return

    const activeContainer = active.data.current.sortable.containerId
    const overContainer = over.data.current?.sortable.containerId || over.id
    const overIndex =
      over.id in lists
        ? lists[overContainer].data.length - 1
        : over.data.current.sortable.index

    if (activeContainer !== overContainer) {
      dispatch({
        type: DragSelectUpdate.MoveItemsBetweenLists,
        fromList: activeContainer,
        targetList: overContainer,
        targetIndex: overIndex,
        additionalItem: active.id
      })
    }
  }

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveId(null)
      return
    }

    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId
      const overContainer = over.data.current?.sortable.containerId || over.id
      const activeIndex = active.data.current.sortable.index
      const overIndex =
        over.id in lists
          ? lists[overContainer].data.length - 1
          : over.data.current.sortable.index

      console.debug('Handling drag end', { activeIndex, overIndex })
      if (activeContainer === overContainer) {
        dispatch({
          type: DragSelectUpdate.MoveItemWithinList,
          targetList: overContainer,
          targetIndex: (activeIndex <= overIndex) ? overIndex + 1 : overIndex,
          additionalItem: active.id
        })
      } else {
        dispatch({
          type: DragSelectUpdate.MoveItemsBetweenLists,
          fromList: activeContainer,
          targetList: overContainer,
          targetIndex: (activeIndex <= overIndex) ? overIndex + 1 : overIndex,
          additionalItem: active.id
        })
      }
    }
    setActiveId(null)
    return true
  }

  return (
    <Container my={8}>
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragOver={handleDragOver} collisionDetection={customCollisionDetection}>
        <SimpleGrid cols={Object.keys(lists).length} className={styles.wrapper}>
          {Object.keys(lists).sort((a, b) => lists[a].index - lists[b].index).map(listId => {
            const list = lists[listId];
            return (
              <Flex key={listId}>
                {list.index - 1 >= 0 && <MoveItemsButton lists={lists} dispatch={dispatch} listHighlighted={highlighted[listId]} dir={-1} listKey={listId} />}
                <DroppableList
                  id={listId}
                  list={list}
                  highlightedItems={highlighted[listId]}
                  handleItemClick={highlightItem}
                  activelyDragging={activeId !== null}
                  clearHighlightedItems={clearHighlightedItems}
                  highlightAll={highlightAll}
                />
                {list.index + 1 <= Object.keys(lists).length - 1 && <MoveItemsButton lists={lists} dispatch={dispatch} listHighlighted={highlighted[listId]} dir={1} listKey={listId} />}
              </Flex>
            );
          })}
        </SimpleGrid>
        <DragOverlay>
          {!!activeId && (
            <DraggableItem key={activeId} id={activeId} className={[styles.draggableItem, styles.dragging]} highlighted dragOverlay>
              {state.entries.get(activeId).label}
            </DraggableItem>
          )}
        </DragOverlay>
      </DndContext>
    </Container>
  );
}

function MoveItemsButton ({ lists, dispatch, listHighlighted, dir = 1, listKey }) {
  const list = lists[listKey]
  const destKey = _.findKey(lists, l => l.index === list.index + dir)
  const destList = lists[destKey]
  const doesNotHaveItems = list.data.length === 0
  const hasHighlightedItems = listHighlighted.length > 0
  const allItemsHighlighted = listHighlighted.length === list.data.length

  return (
    <Tooltip label={(hasHighlightedItems ? `Move highlighted items to ${destList.label}` : `Move all items to ${destList.label}`)}>
      <Button
        mx={8}
        variant='subtle'
        color={hasHighlightedItems ? 'blue' : 'gray'}
        disabled={doesNotHaveItems}
        onClick={() => dispatch({
          type: hasHighlightedItems ? DragSelectUpdate.MoveHighlightedBetweenLists : DragSelectUpdate.MoveItemsBetweenLists,
          fromList: listKey,
          targetList: destKey
        })}
      >
        {dir > 0
          ? (hasHighlightedItems && !allItemsHighlighted ? <IconChevronRight /> : <IconChevronsRight />)
          : (hasHighlightedItems && !allItemsHighlighted ? <IconChevronLeft /> : <IconChevronsLeft />)}
      </Button>
    </Tooltip>
  )
}

export default DragSelect;

function customCollisionDetection (args) {
  const pointerCollisions = pointerWithin(args)

  if (pointerCollisions.length > 0) {
    return pointerCollisions
  }

  return rectIntersection(args)
}
