/* eslint react/prop-types: 0 */
import { Button, Flex, Group, List, Modal, Text, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconSettings } from '@tabler/icons-react'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { getUsersWithAccess, pushToIcims } from '../../../js/api/cycle_repository'
import CycleSubscribe from './CycleSubscribe'
import { useCycleActions } from './DetailViewHooks'
import Content from '../../layout/Content'
import { useAccount } from '../../util/Hooks'
import { NewDropdown } from '../../core/NewDropdown'
import { DropdownListContent } from '../../core/DropdownListContent'

import { CycleStageReplacementAppExternalWrapper } from './invites/stages/CycleStageReplacementApp';

export const DetailViewApp = memo(function DetailViewApp () {
  return (
    <Content>
      <CycleDetailViewActionsContainer />
    </Content>
  )
})

function IcimsButton ({ onClick }) {
  return (
    <Tooltip label='Push cycle data to iCIMS'>
      <Button
        size='compact-sm'
        onClick={onClick}
      >
        iCIMS Push
      </Button>
    </Tooltip>
  )
}

function Item ({ user }) {
  return (
    <Text>
      {user.first_name} {user.last_name} - <i>{user.email}</i>
    </Text>
  )
}

const CycleActionsButton = (
  <Button color='blue' leftSection={<IconSettings />} variant='filled'>
    Cycle Actions
  </Button>
)

export const CycleDetailViewActionsContainer = memo(function CycleDetailViewActionsContainer ({ containerName = 'detail-view-app-container' }) {
  const container = document.getElementById(containerName)
  const hasIcimsAccess = container.hasAttribute('data-has-icims-access')
  const cycleId = container.getAttribute('data-cycle-id')
  const jobPostingSite = container.getAttribute('data-job-posting-site')
  const subscribed = container.hasAttribute('data-is-subscribed')
  const account = useAccount()
  return (
    <CycleDetailViewActions
      cycleId={cycleId}
      jobPostingSite={jobPostingSite}
      subscribed={subscribed}
      hasIcimsAccess={hasIcimsAccess}
      account={account}
    />
  )
})

const CycleDetailViewActions = memo(function CycleDetailViewActions ({ cycleId, jobPostingSite, subscribed, hasIcimsAccess, account }) {
  const [opened, { open, close }] = useDisclosure(false)
  const [usersWithAccess, setUsersWithAccess] = useState([])
  const { ADMIN: hasAdminAccess, PORTAL_CLIENT: hasClientAccess, BETA_TESTER: hasBetaAccess } = useMemo(() => account.access, [account])
  const actions = useCycleActions(cycleId, hasAdminAccess, hasClientAccess, hasBetaAccess, { job_posting_site: jobPostingSite, openUserAccessModal: open })

  useEffect(() => {
    if (!hasAdminAccess) return
    getUsersWithAccess(cycleId).then(response => {
      setUsersWithAccess(response)
    })
  }, [hasAdminAccess, cycleId])

  const getFilteredUsers = useCallback((role) => {
    return usersWithAccess.filter(user => user.roles.map(r => r.name).includes(role))
  }, [usersWithAccess])

  const clientUsers = useMemo(() => getFilteredUsers('ROLE_PORTAL_CLIENT'), [getFilteredUsers])
  const portalUsers = useMemo(() => getFilteredUsers('ROLE_PORTAL_USER'), [getFilteredUsers])

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title='Users and Viewers with Access'
      >
        <List withPadding>
          <h4>Portal Users</h4>
          {clientUsers.length === 0 && <Text fs="italic">No users with access</Text>}
          {clientUsers.map((user, index) => (
            <List.Item key={index}>
              <Item user={user} />
            </List.Item>
          ))}
          <br />
          <h4>Portal Viewers</h4>
          {portalUsers.length === 0 && <Text fs="italic">No viewers with access</Text>}
          {portalUsers.map((viewer, index) => (
            <List.Item key={index}>
              <Item user={viewer} />
            </List.Item>
          ))}
        </List>
      </Modal>
      <Group spacing='xs'>
        {account.id
          ? <>
            <NewDropdown target={CycleActionsButton}>
              <DropdownListContent items={actions}>
              {(hasAdminAccess || hasIcimsAccess) &&
              <Flex>
                <Group>
                  {hasIcimsAccess && <IcimsButton onClick={() => pushToIcims(cycleId)} />}
                  {hasAdminAccess && <CycleSubscribe id={cycleId} isSubscribed={subscribed} />}
                </Group>
              </Flex>}
              </DropdownListContent>
            </NewDropdown>
          </>
          : <>
          <Button disabled leftSection={<IconSettings />} variant='filled'>Cycle Actions</Button>
        </>
        }

      </Group>
      <CycleStageReplacementAppExternalWrapper cycleId={cycleId} />
    </>
  )
})

export { CycleDetailViewActions }
