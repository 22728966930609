import React from 'react'
import HiringTemplateDetail from './HiringTemplateDetail'
import Content from '../../layout/Content'

/**
 * Wrapper component for {@link HiringTemplateDetail} to be able to accept parameters from the root div container.
 * @returns {React.JSX.Element}
 */
export default function HiringTemplateDetailWrapper () {
  const selected = document.getElementById('hiring-template-detail-container').getAttribute('data-selected')
  const id = parseInt(document.getElementById('hiring-template-detail-container').getAttribute('data-program-id'))

  return <Content><HiringTemplateDetail id={id} selected={selected}/></Content>
}
