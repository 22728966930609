import { Button, Checkbox, Container, Divider, Group, Text } from '@mantine/core'
import axios from 'axios'
import React, { useState, useReducer, useMemo } from 'react'
import DragSelect from '../../core/DragSelect/DragSelect'
import Content from '../../layout/Content'
import { showNotification } from '@mantine/notifications'
import {
  createInitialDragSelectState,
  dragSelectStateReducer,
  DragSelectUpdate
} from '../../core/DragSelect/DragSelectState';

const defaultItemsAdmin =
{
  unselected: {
    label: 'Unselected',
    index: 0,
    data: [
      { value: '0', label: 'Cycle Name', cid: 'CycleName' },
      { value: '1', label: 'KeySurvey Respondent ID', cid: 'KsRespondentId' },
      { value: '2', label: 'First Name', cid: 'FirstName' },
      { value: '3', label: 'Last Name', cid: 'LastName' },
      { value: '4', label: 'Email', cid: 'Email' },
      { value: '5', label: 'Phone', cid: 'Phone' },
      { value: '6', label: 'Submit Date', cid: 'SubmitDate' },
      { value: '7', label: 'Hire Date', cid: 'HireDate' },
      { value: '8', label: 'Module Scores (Raw)', cid: 'ModuleRawScores' },
      { value: '9', label: 'Module Scores (Z-score)', cid: 'ModuleZScores' },
      { value: '10', label: 'Module Scores(Percentile)', cid: 'ModulePercScores' },
      { value: '11', label: 'Baseline', cid: 'Baseline' },
      { value: '12', label: 'Notes', cid: 'DiscussionComments' }, // [discussion-to-notes]
      { value: '13', label: 'Status', cid: 'Status' },
      { value: '14', label: 'Flags', cid: 'Flags' },
      { value: '15', label: 'Highest Level of Education', cid: 'HighestEducation' },
      { value: '16', label: 'Education History', cid: 'EducationHistory' },
      { value: '17', label: 'Employment History', cid: 'EmploymentHistory' },
      { value: '18', label: 'Demographics', cid: 'Demographics' },
      { value: '19', label: 'Applied Location', cid: 'Region' },
      { value: '20', label: 'BVI Responses', cid: 'BviResponses' },
      { value: '21', label: 'KeySurvey Responses', cid: 'KeySurveyResponses' },
      { value: '22', label: 'Location Preferences', cid: 'LocationPreferences' },
      { value: '23', label: 'Position Preferences', cid: 'PositionPreferences' },
      { value: '24', label: 'Levels of Expertise', cid: 'LevelsOfExpertise' },
      { value: '25', label: 'Baseline (unrounded)', cid: 'BaselineUnrounded' }
    ]
  },
  selected: {
    label: 'Selected',
    index: 1,
    data: []
  }
}

const defaultItems =
{
  unselected: {
    label: 'Unselected',
    index: 0,
    data: [
      { value: '0', label: 'Cycle Name', cid: 'CycleName' },
      { value: '1', label: 'First Name', cid: 'FirstName' },
      { value: '2', label: 'Last Name', cid: 'LastName' },
      { value: '3', label: 'Email', cid: 'Email' },
      { value: '4', label: 'Phone', cid: 'Phone' },
      { value: '5', label: 'Submit Date', cid: 'SubmitDate' },
      { value: '6', label: 'Hire Date', cid: 'HireDate' },
      { value: '7', label: 'Module Scores (Raw)', cid: 'ModuleRawScores' },
      { value: '8', label: 'Module Scores(Percentile)', cid: 'ModulePercScores' },
      { value: '9', label: 'Baseline', cid: 'Baseline' },
      { value: '10', label: 'Notes', cid: 'DiscussionComments' }, // [discussion-to-notes]
      { value: '11', label: 'Status', cid: 'Status' },
      { value: '12', label: 'Flags', cid: 'Flags' },
      { value: '13', label: 'Highest Level of Education', cid: 'HighestEducation' },
      { value: '14', label: 'Education History', cid: 'EducationHistory' },
      { value: '15', label: 'Employment History', cid: 'EmploymentHistory' },
      { value: '16', label: 'Applied Location', cid: 'Region' }
    ]
  },
  selected: {
    label: 'Selected',
    index: 1,
    data: []
  }
}

const defaultItemsWithDemographics =
{
  unselected: {
    label: 'Unselected',
    index: 0,
    data: [
      { value: '0', label: 'Cycle Name', cid: 'CycleName' },
      { value: '1', label: 'First Name', cid: 'FirstName' },
      { value: '2', label: 'Last Name', cid: 'LastName' },
      { value: '3', label: 'Email', cid: 'Email' },
      { value: '4', label: 'Phone', cid: 'Phone' },
      { value: '5', label: 'Submit Date', cid: 'SubmitDate' },
      { value: '6', label: 'Hire Date', cid: 'HireDate' },
      { value: '7', label: 'Module Scores (Raw)', cid: 'ModuleRawScores' },
      { value: '8', label: 'Module Scores(Percentile)', cid: 'ModulePercScores' },
      { value: '9', label: 'Baseline', cid: 'Baseline' },
      { value: '10', label: 'Notes', cid: 'DiscussionComments' }, // [discussion-to-notes]
      { value: '11', label: 'Status', cid: 'Status' },
      { value: '12', label: 'Flags', cid: 'Flags' },
      { value: '13', label: 'Highest Level of Education', cid: 'HighestEducation' },
      { value: '14', label: 'Education History', cid: 'EducationHistory' },
      { value: '15', label: 'Employment History', cid: 'EmploymentHistory' },
      { value: '16', label: 'Demographics', cid: 'Demographics' },
      { value: '17', label: 'Applied Location', cid: 'Region' }
    ]
  },
  selected: {
    label: 'Selected',
    index: 1,
    data: []
  }
}

const templates = {
  applicantResults: [
    { value: '2', label: 'First Name', cid: 'FirstName' },
    { value: '3', label: 'Last Name', cid: 'LastName' },
    { value: '4', label: 'Email', cid: 'Email' },
    { value: '6', label: 'Submit Date', cid: 'SubmitDate' },
    { value: '8', label: 'Module Scores (Raw)', cid: 'ModuleRawScores' },
    { value: '13', label: 'Status', cid: 'Status' },
    { value: '14', label: 'Flags', cid: 'Flags' },
    { value: '18', label: 'Demographics', cid: 'Demographics' },
    { value: '12', label: 'Notes', cid: 'DiscussionComments' }
  ],
  phaseI: [
    { value: '2', label: 'First Name', cid: 'FirstName' },
    { value: '3', label: 'Last Name', cid: 'LastName' },
    { value: '4', label: 'Email', cid: 'Email' },
    { value: '5', label: 'Phone', cid: 'Phone' },
    { value: '6', label: 'Submit Date', cid: 'SubmitDate' },
    { value: '13', label: 'Status', cid: 'Status' },
    { value: '10', label: 'Module Scores(Percentile)', cid: 'ModulePercScores' },
    { value: '12', label: 'Notes', cid: 'DiscussionComments' },
    { value: '14', label: 'Flags', cid: 'Flags' },
    { value: '16', label: 'Education History', cid: 'EducationHistory' },
    { value: '17', label: 'Employment History', cid: 'EmploymentHistory' },
    { value: '21', label: 'KeySurvey Responses', cid: 'KeySurveyResponses' }
  ],
  phaseII: [
    { value: '2', label: 'First Name', cid: 'FirstName' },
    { value: '3', label: 'Last Name', cid: 'LastName' },
    { value: '4', label: 'Email', cid: 'Email' },
    { value: '5', label: 'Phone', cid: 'Phone' },
    { value: '6', label: 'Submit Date', cid: 'SubmitDate' },
    { value: '13', label: 'Status', cid: 'Status' },
    { value: '10', label: 'Module Scores(Percentile)', cid: 'ModulePercScores' },
    { value: '8', label: 'Module Scores (Raw)', cid: 'ModuleRawScores' },
    { value: '12', label: 'Notes', cid: 'DiscussionComments' }, // [discussion-to-notes]
    { value: '14', label: 'Flags', cid: 'Flags' },
    { value: '16', label: 'Education History', cid: 'EducationHistory' },
    { value: '17', label: 'Employment History', cid: 'EmploymentHistory' },
    { value: '21', label: 'KeySurvey Responses', cid: 'KeySurveyResponses' }
  ]
}

const selectedKey = 'selected'
const unselectedKey = 'unselected'

export default function CustomExportApp () {
  const [loading, setLoading] = useState(false);
  const cycleId = document.querySelector('[data-cycle-id]').getAttribute('data-cycle-id');
  const [ensureNumeric, setEnsureNumeric] = useState(false);
  const [includeRawOIScores, setIncludeRawOIScores] = useState(false);
  const isHiringView = parseInt(document.querySelector('[data-is-hiring-view]').getAttribute('data-is-hiring-view'));
  const isAdmin = parseInt(document.querySelector('[data-is-user-admin]').getAttribute('data-is-user-admin'));
  const isDemographicsRole = parseInt(document.querySelector('[data-is-user-demographics-role]').getAttribute('data-is-user-demographics-role'));

  const url = isHiringView === 1
    ? `/hire/report-results/hiring-views/${cycleId}/custom-export`
    : `/cycles/${cycleId}/customExport`

  const initialLists = isAdmin === 1
    ? defaultItemsAdmin
    : isDemographicsRole === 1
      ? defaultItemsWithDemographics
      : defaultItems;

  const [state, dispatch] = useReducer(
    dragSelectStateReducer,
    initialLists,
    createInitialDragSelectState
  )
  const [formattedLists, formattedHighlighted] = useMemo(() => {
    const entriesByList = {}
    const highlightedByList = {}

    state.lists.forEach((value, key, map) => {
      entriesByList[key] = []
      highlightedByList[key] = []
    })

    state.entries.forEach((value, key, map) => {
      entriesByList[value.list].push(value)
      if (value.highlighted) {
        highlightedByList[value.list].push(value)
      }
    })
    const returnLists = {}
    state.lists.forEach((value, key, map) => {
      returnLists[key] = { ...value, data: entriesByList[key] }
    })

    return [returnLists, highlightedByList]
  }, [state])

  const applyTemplate = (templateName) => {
    const template = templates[templateName];
    dispatch({ type: DragSelectUpdate.SetListItems, targetList: selectedKey, defaultList: unselectedKey, items: template })
    // dragSelectRef.current?.setLists(template);
  };

  const submit = () => {
    axios({
      method: 'post',
      url: url,
      data: {
        choices: formattedLists[selectedKey].data.map((i) => i.id).join(','),
        ensureNumeric: ensureNumeric,
        includeRawOIScores: includeRawOIScores
      },
      responseType: 'blob'
    })
      .then(function (response) {
        const fileName = (response.headers['content-disposition'].split('filename=')[1]).replace(/"+/g, '')
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // This is for IE >:(
          window.navigator.msSaveOrOpenBlob(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
          ),
          fileName
          )
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)

          document.body.appendChild(link)
          link.click()
        }
      })
      .catch(function (error) {
        console.error(error)
        showNotification({
          message: 'There was an error generating custom export',
          color: 'red',
          autoClose: true
        })
      })
      .finally(() => setLoading(false))
  }

  const onClick = () => {
    if (formattedLists[selectedKey].data.length === 0) {
      alert('No items selected for export.');
    } else {
      setLoading(true);
      submit();
    }
  };

  return (
    <Content>
      <Container>
        <Text size="xl">Customize Export</Text>
        {isAdmin === 1 && (
          <Group position="left" mt="sm">
            <Button onClick={() => applyTemplate('applicantResults')}>Applicant Results</Button>
            <Button onClick={() => applyTemplate('phaseI')}>Phase I</Button>
            <Button onClick={() => applyTemplate('phaseII')}>Phase II</Button>
          </Group>
        )}
        <Divider mt="md" />
        <DragSelect lists={formattedLists} highlighted={formattedHighlighted} state={state} dispatch={dispatch} />
        <Checkbox label="Additional Type Validation of Numeric Columns" checked={ensureNumeric} onChange={(event) => setEnsureNumeric(event.currentTarget.checked)} />
        {isAdmin === 1 && (
          <Checkbox label="Include Raw OI Scores" checked={includeRawOIScores} onChange={(event) => setIncludeRawOIScores(event.currentTarget.checked)} />
        )}
        <Button mt="md" color="blue.6" onClick={onClick} loading={loading}>
          Export
        </Button>
      </Container>
    </Content>
  )
}
