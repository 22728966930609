// extracted by mini-css-extract-plugin
var _1 = "_qZkSlWBH4gz64aakuS5";
var _2 = "ylgoOJ6zQFR_Jcs0S8OG";
var _3 = "M3jjtaPu0LGv1EPjZAve";
var _4 = "KWiRwIzYjCIFY1OZwzZw";
var _5 = "WVZNvZnnxdcwFg9D4H9A";
var _6 = "C0xKgR9XoDd6GQlPnXBp";
var _7 = "WHiGv90gqdioXRc1ptpR";
var _8 = "CIQfFi3TRGRwTUo9aTRu";
var _9 = "h4jPBCaS8FBjmUtyv4jQ";
export { _1 as "body", _2 as "cloneProfileWrapper", _3 as "dimensionTable", _4 as "header", _5 as "importanceCell", _6 as "mantineSwitchBody", _7 as "row", _8 as "rowActions", _9 as "visibilityCell" }
