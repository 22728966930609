import React from 'react'
import { ActionIcon, Anchor, Badge, Center, Group, Skeleton, Table, Text, Tooltip } from '@mantine/core'
import { IconCalendarRepeat, IconLink, IconRefresh, IconTrash } from '@tabler/icons-react'
import * as styles from './CycleListDisplay.module.scss'
import { format } from '../../../util/date.util'
import { isGranted } from '../../../util/account/account.util'
import { useGetLoggedInAccountQuery } from '../../../redux/query/account/accountsApi.slice'
import ErrorLoading from '../../core/ErrorLoading'

/**
 * This component displays a panel with information about a specific cycle. It allows to include basic cycle actions
 * and customize its size.
 * @typedef {object} CycleListDisplayProps
 * @property {import("../../../models/cycleList.d").CycleList} cycle The cycle to display information about.
 * @property {boolean} showActions If the basic cycle actions should be included.
 * @property {string} [variant] The size of the display. Acceptable options are "default" and "c
 * @param {CycleListDisplayProps} props
 * @returns {React.ReactElement}
 */
function CycleListDisplay ({ cycle, showActions, variant = 'default' }) {
  const { data: account, error, isLoading } = useGetLoggedInAccountQuery()

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <>
      <Skeleton height={variant === 'compact' ? 10 : 25} width={variant === 'compact' ? 250 : 350} mb={variant === 'compact' ? 'xs' : 'sm'} />
      {showActions && <Skeleton height={variant === 'compact' ? 10 : 25} width={variant === 'compact' ? 50 : 100} mb={variant === 'compact' ? 'xs' : 'sm'} />}
      <Skeleton height={variant === 'compact' ? 10 : 25} width={variant === 'compact' ? 250 : 350} mb='xs'/>
      <Skeleton height={variant === 'compact' ? 10 : 25} width={variant === 'compact' ? 250 : 350} mb={variant === 'compact' ? 's' : 'md'} />
    </>
  }

  return (
    <>
      <Group><Anchor href={`/cycles/${cycle.id}/results`} size={variant === 'compact' ? 'sm' : 'xl'} truncate='end' fw={700} span>{cycle.name}</Anchor><Badge color={cycle.active === 0 ? 'green.6' : 'red.6'} size={variant === 'compact' ? 'xs' : 'md'}>{cycle.active === 0 ? 'Open' : 'Closed'}</Badge></Group>
      {
        showActions &&
        <ActionIcon.Group>
          {
            cycle.job_posting_site !== '' &&
            <Tooltip label='Job Posting Site'>
              <ActionIcon color='gray.6' variant='subtle' aria-label='Job Posting Site' onClick={() => window.open(cycle.job_posting_site, '_blank')}>
                <IconLink />
              </ActionIcon>
            </Tooltip>
          }
          <Tooltip label='Update Cycle'>
            <ActionIcon color='gray.6' variant='subtle' aria-label='Update Cycle' onClick={() => (window.location.href = `/cycles/${cycle.id}/update`)}>
              <IconCalendarRepeat />
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Refresh Cycle'>
            <ActionIcon color='gray.6' variant='subtle' aria-label='Refresh Cycle' onClick={() => (window.location.href = `/cycles/${cycle.id}/refresh`)}>
              <IconRefresh />
            </ActionIcon>
          </Tooltip>
          {
            isGranted(account, 'ROLE_DEVELOPER') &&
            <Tooltip label='Delete Cycle'>
              <ActionIcon color='gray.6' variant='subtle' aria-label='Delete Cycle' onClick={() => (window.location.href = `/cycles/${cycle.id}/delete`)}>
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          }
        </ActionIcon.Group>
      }
      <Table className={styles.table}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Organization</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Location</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Open Date</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Close Date</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Project Manager</Text></Center></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{cycle.location.organization.name}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{cycle.location.name}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{format(cycle.start_date)}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{typeof cycle.end_date !== 'undefined' ? format(cycle.end_date) : '-'}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{cycle.location.organization.manager?.email ?? '-'}</Text></Center></Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  )
}

export default React.memo(CycleListDisplay)
