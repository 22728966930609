/* eslint react-hooks/exhaustive-deps: 0 */
import { ActionIcon, Box, CloseButton, Group, Indicator, ScrollArea, Text } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { IconBell } from '@tabler/icons-react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as DOMPurify from 'dompurify'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { getUnreadNotifications, markAsRead } from '../../js/api/notification_repository'
import Dropdown from './Dropdown'
import * as styles from './SystemNotification.module.scss'

dayjs.extend(relativeTime)

export default function SystemNotification () {
  const [notifications, setNotifications] = useState([])
  const interval = useInterval(() => getUnreadNotifications().then(notifications => setNotifications(notifications)), 120000)
  const redirectContexts = ['APPLICANT_COMPLETED_AP_TESTING', 'APPLICANT_DUPLICATE_DETECTED', 'TRIGGER_ACTIVATED', 'TRIGGER_TOGGLED_BY_SYSTEM']

  useEffect(() => {
    getUnreadNotifications().then(notifications => setNotifications(notifications))
    interval.start()
    return interval.stop
  }, [])

  const close = notification => {
    return markAsRead(notification.id).then(() => setNotifications(prev => _.without(prev, notification)))
  }

  const redirect = notification => {
    if (redirectContexts.includes(notification.context)) {
      close(notification).then(() => {
        window.location.href = '/cycles/' + notification.extra.cycle_id + '/results'
      })
    }
  }

  const NotificationButton = (
    <Indicator color='red' disabled={_.size(notifications) === 0} offset={8} size={12} withBorder>
      <ActionIcon color='gray'>
        <IconBell size={22} />
      </ActionIcon>
    </Indicator>
  )

  return (
    <Dropdown target={NotificationButton}>
      <Box w='400px'>
      <ScrollArea.Autosize mah='30rem'>
      {_.size(notifications) === 0 && <Text ta='center'>You are up to date!</Text>}
      {_.map(notifications, notification => (
        <Box key={`notification-${notification.id}`} className={styles.notification}>
            <Box className={styles.content}>
              <Group justify='space-between'>
                <Text>{notification.title}</Text>
                <Text>{dayjs(notification.updated_at).fromNow()}</Text>
              </Group>
              <Box className={[styles.description, (redirectContexts.includes(notification.context) ? styles.hasLink : '')]} onClick={() => redirect(notification)}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.description) }}></div></Box>
            </Box>
            <Box className={styles.control}>
              <CloseButton onClick={() => close(notification)}/>
            </Box>
        </Box>
      ))}
      </ScrollArea.Autosize>
      </Box>
    </Dropdown>
  )
}
