import React from 'react'
import { Divider, Paper, ScrollArea, SimpleGrid, Tabs, Title } from '@mantine/core'
import ErrorLoading from '../../core/ErrorLoading'
import Loading from '../../core/Loading'
import { useGetCyclesQuery } from '../../../redux/query/hire/cyclesApi.slice'
import { useGetHiringTemplatesQuery } from '../../../redux/query/build/hiringTemplatesApi.slice'
import { IconBox, IconTemplate } from '@tabler/icons-react'
import CycleListDisplay from '../../hire/cycle/CycleListDisplay'
import HiringTemplateListDisplay from '../hiring_template/HiringTemplateListDisplay'

/**
 * This component renders a panel that displays which cycles and hiring templates depend on the given AP battery.
 * @param {object} props
 * @param {string|number} props.id The id of the AP battery.
 * @returns {React.JSX.Element}
 */
export default function BatteryDependencies ({ id }) {
  const { data: cycles, error: cyclesError, isLoading: cyclesLoading } = useGetCyclesQuery({ battery: id, limit: 0 })
  const { data: hiringTemplates, error: hiringTemplatesError, isLoading: hiringTemplatesLoading } = useGetHiringTemplatesQuery({ battery: id, limit: 0 })

  if (cyclesError || hiringTemplatesError) {
    return <ErrorLoading />
  }

  if (cyclesLoading || hiringTemplatesLoading) {
    return <Loading />
  }

  return (
        <Paper shadow='xs' p='xl' withBorder>
            <Title order={4}>This battery is assigned to these cycles/hiring templates.</Title>
            <Tabs defaultValue='cycles'>
                <Tabs.List>
                    <Tabs.Tab value='cycles' color='green.6' leftSection={<IconBox size='1rem'/>}>Cycles</Tabs.Tab>
                    <Tabs.Tab value='hiring_templates' color='yellow.6' leftSection={<IconTemplate size='1rem'/>}>Hiring Templates</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='cycles'>
                    <ScrollArea
                        h={300}
                        type='auto'
                    >
                        <SimpleGrid cols={1} mt='md'>
                            {cycles.items.map((cycle, i) => (
                                <React.Fragment key={cycle.id}>
                                    <CycleListDisplay cycle={cycle} showActions={false} variant='compact'/>
                                    {i !== cycles.items.length - 1 && <Divider variant='dashed' size='sm' />}
                                </React.Fragment>
                            ))}
                            {cycles.items.length === 0 && 'No cycles are using this battery.'}
                        </SimpleGrid>
                    </ScrollArea>
                </Tabs.Panel>

                <Tabs.Panel value='hiring_templates'>
                    <ScrollArea
                        h={300}
                        type='auto'
                    >
                        <SimpleGrid cols={1} mt='md'>
                            {hiringTemplates.items.map((hiringTemplate, i) =>
                                <React.Fragment key={hiringTemplate.id}>
                                    <HiringTemplateListDisplay hiringTemplate={hiringTemplate} variant='compact'/>
                                    {i !== hiringTemplates.items.length - 1 && <Divider variant='dashed' size='sm' />}
                                </React.Fragment>
                            )}
                            {hiringTemplates.items.length === 0 && 'No hiring templates are using this battery.'}
                        </SimpleGrid>
                    </ScrollArea>
                </Tabs.Panel>
            </Tabs>
        </Paper>
  )
}
