import React, { useEffect, useState } from 'react'
import * as styles from './AccountTabs.module.scss';
import { Accordion, Grid, SimpleGrid, Title, Text, Flex, List, Badge } from '@mantine/core';
import { IconAlertTriangleFilled, IconFileExport } from '@tabler/icons-react';
import { myAccountData, myAccountDataStatus } from '../../js/api/account_repository';
import { showNotification } from '@mantine/notifications';
import ConfirmationModal from '../core/ConfirmationModal';

export default function Privacy () {
  const [action, setAction] = useState(null)
  const [status, setStatus] = useState([])

  useEffect(() => {
    if (action !== null) {
      myAccountData({ action })
        .then(() => {
          showNotification({
            message: 'Your request has successfully been sent.',
            color: 'success',
            loading: false,
            autoClose: 3000
          })
        })
        .catch(() => {
          showNotification({
            message: 'Something went wrong.',
            color: 'red',
            loading: false,
            autoClose: 3000
          })
        })
    }
  }, [action])

  useEffect(() => {
    myAccountDataStatus().then(response => {
      setStatus(response)
    })
  }, [])

  return (
    <Grid className={styles.grid}>
      <Grid.Col span={2}>
        <Title order={4}>Data</Title>
      </Grid.Col>
      <Grid.Col span={5}>
        <SimpleGrid cols={1} verticalSpacing='xl'>
          <Accordion multiple>
            <Accordion.Item value="view">
              <Accordion.Control>Request Data</Accordion.Control>
              <Accordion.Panel>
                <Text size={'sm'}>
                  Request a copy of your data.
                  Requests are typically fulfilled within 30 business days.
                  For more information contact <a href="mailto:support@hirescore.com"> support@hirescore.com</a>.
                </Text>
                <Flex
                  mih={50}
                  gap="md"
                  justify="flex-start"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <ConfirmationModal
                    openButtonContent={'Request'}
                    openButtonDisabled={status !== undefined ? status.find(x => x.action === 'VIEW')?.status === 'PENDING' : false}
                    header='Get Data'
                    headerBgColor='success'
                    headerIcon={<IconFileExport style={{ color: 'white' }} size={48}/>}
                    contentText='Once complete a copy of your data will be delivered to the email address linked to your account.'
                    submitButtonColor='success'
                    onSubmit={() => setAction('VIEW')}
                  />
                  {(status !== undefined ? status.find(x => x.action === 'VIEW')?.status === 'PENDING' : false) &&
                    <Badge color="gray" size="lg" variant="filled">Pending</Badge>
                  }
                  {(status !== undefined ? status.find(x => x.action === 'VIEW')?.status === 'COMPLETED' : false) &&
                    <Badge color="green" size="lg" variant="filled">Completed</Badge>
                  }
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="stop">
              <Accordion.Control>Stop Processing Data</Accordion.Control>
              <Accordion.Panel>
                <Text size={'sm'}>
                  Request that your data is no longer used.
                  Requests are typically fulfilled within 30 business days.
                  For more information or to reverse your decision contact
                  <a href="mailto:support@hirescore.com"> support@hirescore.com</a>.
                </Text>
                <Flex
                  mih={50}
                  gap="md"
                  justify="flex-start"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <ConfirmationModal
                    openButtonColor='yellow'
                    openButtonContent='Stop'
                    openButtonDisabled={
                      /*
                      * Disable the button if the request is Pending or Completed. We could add the functionality for
                      * them to enroll again but for now they can just contact the support email.
                      */
                      status !== undefined
                        ? (
                            status.find(x => x.action === 'STOP')?.status === 'PENDING' ||
                        status.find(x => x.action === 'STOP')?.status === 'COMPLETED'
                          )
                        : false}
                    headerIcon={<IconAlertTriangleFilled style={{ color: 'white' }} size={48}/>}
                    header='Stop Processing'
                    headerBgColor='yellow'
                    contentText='Upon completion of the request the following will happen.'
                    contentElement={
                      <List size='sm'>
                        <List.Item>
                          Your application(s) will be hidden from the system and you will no longer be shown as a
                          candidate for any applicable jobs.
                        </List.Item>
                      </List>
                    }
                    submitButtonColor='yellow'
                    onSubmit={() => setAction('STOP')}
                  />
                  {(status !== undefined ? status.find(x => x.action === 'STOP')?.status === 'PENDING' : false) &&
                    <Badge color="gray" size="lg" variant="filled">Pending</Badge>
                  }
                  {(status !== undefined ? status.find(x => x.action === 'STOP')?.status === 'COMPLETED' : false) &&
                    <Badge color="green" size="lg" variant="filled">Completed</Badge>
                  }
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="delete">
              <Accordion.Control>Delete Data</Accordion.Control>
              <Accordion.Panel>
                <Text size={'sm'}>
                  Request that your data be removed from Hirescore.
                  Requests are typically fulfilled within 30 business days.
                  For more information or to reverse your decision contact
                  <a href="mailto:support@hirescore.com"> support@hirescore.com</a>.
                </Text>
                <Flex
                  mih={50}
                  gap="md"
                  justify="flex-start"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <ConfirmationModal
                    openButtonColor='red'
                    openButtonContent='Delete'
                    openButtonDisabled={status !== undefined ? status.find(x => x.action === 'DELETE')?.status === 'PENDING' : false}
                    headerIcon={<IconAlertTriangleFilled style={{ color: 'white' }} size={48}/>}
                    header='Delete Data'
                    headerBgColor='red'
                    contentText='Upon completion of the request the following will happen.'
                    contentElement={
                      <List size='sm'>
                        <List.Item>
                          Your application(s) will be removed from the system and you will no longer be shown as a
                          candidate for any applicable jobs.
                        </List.Item>
                        <List.Item>
                          Your account will be removed from the system and you will no longer have access to HireScore.
                        </List.Item>
                      </List>
                    }
                    submitButtonColor='red'
                    onSubmit={() => setAction('DELETE')}
                  />
                  {(status !== undefined ? status.find(x => x.action === 'DELETE')?.status === 'PENDING' : false) &&
                    <Badge color="gray" size="lg" variant="filled">Pending</Badge>
                  }
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </SimpleGrid>
      </Grid.Col>
    </Grid>
  )
}
