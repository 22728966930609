/* eslint react/prop-types: 0 */
import { useFormContext, useWatch } from 'react-hook-form';
import * as styles from './Conditions.module.scss';
import BaselineCondition from './BaselineCondition';
import PhasesDropdown from './PhasesDropdown';
import StatusCondition from './StatusCondition';
import Error from '../../../../forms/Error';
import React from 'react';
import { ActionIcon, Divider } from '@mantine/core'
import { IconMinus } from '@tabler/icons-react';
import { useGetPhasesQuery } from '../../../../../redux/query/hire/phasesApi.slice'
import ErrorLoading from '../../../../core/ErrorLoading'
import Loading from '../../../../core/Loading'
import _ from 'lodash'

export default function Conditions ({ statusSet, index, remove }) {
  const { register } = useFormContext()
  const conditions = useWatch({ name: 'conditions' })
  const code = useWatch({ name: `conditions.${index}.code` })

  return (
    <>
      <div className={styles.conditionRow}>
        <select
          className={styles.conditionSelect}
          {...register(`conditions.${index}.code`, {
            required: 'Select a condition',
            validate: value => value !== 'default'
          })}
        >
          <option value='default'>Choose a Condition</option>
          <option
            value='APPLICANT_STATUS_EQUALS'
            hidden={conditions?.filter(e => e.code === 'APPLICANT_STATUS_EQUALS').length > 0}
          >
            Applicant Status
          </option>
          <option
            value='ABOVE_CUT_SCORES_AFTER_PHASE'
            hidden={conditions?.filter(condition => condition.code === 'BELOW_CUT_SCORES_AFTER_PHASE').length > 0}
          >
            Applicant Above Cut Scores
          </option>
          <option
            value='BELOW_CUT_SCORES_AFTER_PHASE'
            hidden={conditions?.filter(condition => condition.code === 'ABOVE_CUT_SCORES_AFTER_PHASE').length > 0}
          >
            Applicant Below Cut Scores
          </option>
          <option value='BASELINE_HIGHER_THAN_OR_EQUAL_TO_AFTER_PHASE'>Applicant Baseline Score</option>
        </select>
        {code === 'BASELINE_HIGHER_THAN_OR_EQUAL_TO_AFTER_PHASE' && <BaselineCondition />}
        {code === 'ABOVE_CUT_SCORES_AFTER_PHASE' && <PhasesDropdown name={'metadata.cut_score_phase'}/>}
        {code === 'BELOW_CUT_SCORES_AFTER_PHASE' && <PhasesDropdown name={'metadata.cut_score_phase'}/>}
        {code === 'APPLICANT_STATUS_EQUALS' && <StatusCondition statusSet={statusSet}/>}
        {index > 0 && <ActionIcon onClick={() => remove(index)}><IconMinus /></ActionIcon>}
      </div>
      <div className={styles.conditionExplanation}>
        <ConditionExplanation index={index}/>
      </div>
      <Error name={`conditions.${index}.code`} customMessage='Select a condition' />
      {index !== (conditions?.length - 1) && <Divider/>}
    </>
  )
}

function ConditionExplanation ({ index }) {
  const condition = useWatch({ name: `conditions.${index}` })
  const metadata = useWatch({ name: 'metadata' })

  const { data: phases, error, isLoading } = useGetPhasesQuery()

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <Loading />
  }

  let text = ''

  switch (condition.code) {
    case 'BASELINE_HIGHER_THAN_OR_EQUAL_TO_AFTER_PHASE':
      text = <p>If applicants baseline score is greater than or equal to [Limit] after [Phase] is completed.</p>
      if (
        typeof metadata !== 'undefined' &&
        typeof metadata.phase !== 'undefined' &&
        metadata.baseline !== ''

      ) {
        text = <p>If applicants Baseline score is greater than or equal to {metadata.baseline} after {_.find(phases.items, { id: parseInt(metadata.phase) })?.name} is completed.</p>
      }
      break
    case 'ABOVE_CUT_SCORES_AFTER_PHASE':
      text = <p>If applicants scores are above cut scores after [Phase] is completed.</p>
      if (
        typeof metadata !== 'undefined' &&
        typeof metadata.cut_score_phase !== 'undefined'
      ) {
        text = <p>If applicants scores are above cut scores after {_.find(phases.items, { id: parseInt(metadata.cut_score_phase) })?.name} is completed.</p>
      }
      break
    case 'BELOW_CUT_SCORES_AFTER_PHASE':
      text = <p>If applicants scores are below cut scores after [Phase] is completed.</p>
      if (
        typeof metadata !== 'undefined' &&
        typeof metadata.cut_score_phase !== 'undefined'
      ) {
        text = <p>If applicants scores are below cut scores after {_.find(phases.items, { id: parseInt(metadata.cut_score_phase) })?.name} is completed.</p>
      }
      break
    case 'APPLICANT_STATUS_EQUALS':
      text = <p>If applicants status equals any of [Statuses].</p>
      if (
        typeof metadata !== 'undefined' &&
        typeof metadata.applicant_status !== 'undefined' &&
        metadata.applicant_status !== null &&
        metadata.applicant_status.length !== 0
      ) {
        text = <p>If applicants status equals any of {metadata.applicant_status.join(', ')}.</p>
      }
      break
  }

  return <>{text}</>
}
