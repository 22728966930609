/* eslint react-hooks/exhaustive-deps: 0 */
import { Box, Button, Group, Textarea } from '@mantine/core'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import React, { useEffect, useRef } from 'react'
import * as styles from './Consequents.module.scss'
import _ from 'lodash'
import { randomId } from '@mantine/hooks'

export default function NotifySubscribersConsequent () {
  const { control, setValue, getValues } = useFormContext()
  const [placeholders, setPlaceholders] = React.useState([])

  const textAreaElement = useRef()
  const conditions = useWatch({ name: 'conditions' })

  useEffect(() => {
    const codes = []
    codes.push('Applicant Name')
    codes.push('Cycle Name')

    conditions.forEach((condition) => {
      switch (condition.code) {
        case 'APPLICANT_STATUS_EQUALS':
          codes.push('Applicant Status')
          break

        case 'BASELINE_HIGHER_THAN_OR_EQUAL_TO_AFTER_PHASE':
          codes.push('Baseline Score')
          break
      }
    })
    const placeholderList = _.uniq(codes).map((code) => {
      return (
        <Button
          key={randomId()}
          variant='outline'
          onClick={handlePlaceholderClick}
          radius='xl'
          color='gray'
          size='compact-sm'
        >
          {code}
        </Button>
      )
    })
    setPlaceholders(placeholderList)
  }, [useWatch({ name: 'conditions' })])

  const handlePlaceholderClick = (e) => {
    const placeholder = getCode(e.target.innerText)
    const textArea = textAreaElement.current

    textArea.focus()

    if (textArea.selectionStart || textArea.selectionStart === '0') {
      const pos = { start: textArea.selectionStart, end: textArea.selectionEnd }
      textArea.value = (textArea.value.substring(0, pos.start) +
        placeholder +
        textArea.value.substring(pos.end, textArea.value.length) +
        ' ')
      textArea.selectionStart = pos.start + placeholder.length + 1
      textArea.selectionEnd = pos.start + placeholder.length + 1
    } else {
      textArea.value += (placeholder + ' ')
    }
    setValue('metadata.notification_text', textArea.value)
  }

  const getCode = (text) => {
    switch (text) {
      case 'Applicant Name':
        return '%APPLICANT_NAME%'
      case 'Cycle Name':
        return '%CYCLE_NAME%'
      case 'Applicant Status':
        return '%APPLICANT_STATUS%'
      case 'Baseline Score':
        return '%BASELINE_SCORE%'
    }
  }

  return (
    <>
    <Group align="center" className={styles.placeholderContainer}>
      {placeholders.map((placeholder, index) => {
        return (
          <Box key={index}>
            {placeholder}
          </Box>
        )
      })}
    </Group>
    <Controller
        name={'metadata.notification_text'}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Textarea
              placeholder={'Notification text'}
              onChange={onChange}
              ref={textAreaElement}
              className={styles.notificationTextArea}
              minRows={3}
              maxRows={5}
              value={getValues('metadata.notification_text')}
            />
          )
        }}
      />
    </>
  )
}
