/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { useController, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import Error from '../../../../forms/Error'
import * as styles from './Consequents.module.scss'
import axios from 'axios'
import { ActionIcon, Button, Divider, Flex, Grid, Modal, Radio, Stack, Tooltip } from '@mantine/core'
import TemplateSelect from './TemplateSelect'
import BatterySelect from './BatterySelect'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import _ from 'lodash'
import WarningAlert from '../../../../core/Alert/WarningAlert'

export default function ContactConsequent () {
  const [opened, handlers] = useDisclosure(false)
  const { control, register } = useFormContext();
  const {
    fields: contactApplicantFields,
    append: contactApplicantAppend,
    remove: contactApplicantRemove,
    insert: contactApplicantInsert
  } = useFieldArray({ control: control, name: 'metadata.emails', shouldUnregister: true })

  const emailsWatch = useWatch({ control: control, name: 'metadata.emails', defaultValue: contactApplicantFields })

  const hasInvite = _.some(emailsWatch, email => typeof email !== 'undefined' && typeof email.email_program !== 'undefined')

  const maxScheduled = _.reduce(emailsWatch, (sum, email) => Math.max(email?.after_execution ?? 0, sum), 0)

  const metadata = useWatch({ name: 'metadata' })

  useEffect(() => {
    if (contactApplicantFields.length === 0) {
      contactApplicantAppend()
    }
  }, [])

  return (
    <>
      <Modal opened={opened} onClose={handlers.close} title='Settings' keepMounted={true}>
        <label>Amount of days until display deadline
          <input type='number' {...register('metadata.days_until_display_deadline', {
            required: hasInvite ? 'Please select how many days until the deadline' : false,
            pattern: {
              value: /^\d+$/,
              message: 'This must be a number'
            }
          })}/>
        </label>
        <Error name={'metadata.days_until_display_deadline'}/>
      </Modal>
      {contactApplicantFields.map((item, index) => (
        <TemplateSelection
          key={item.id}
          index={index}
          control={control}
          value={item}
          remove={contactApplicantRemove}
          insert={contactApplicantInsert}
        />
      ))}
      {
        hasInvite &&
        <>
          <Flex
            gap='xs'
            justify='flex-end'
            align='center'
            direction='row'
            wrap='wrap'
          >
            <Button variant='subtle' color='blue.6' size='xs' onClick={handlers.open}>Settings</Button>
          </Flex>
          {hasInvite && metadata.days_until_display_deadline < maxScheduled && <WarningAlert>There is an email scheduled to be sent after the defined display deadline.</WarningAlert>}
          <Error name={'metadata.days_until_display_deadline'}/>
        </>
      }
    </>
  )
}

function TemplateSelection ({ index, remove, insert }) {
  const { getValues } = useFormContext()
  const templateId = useWatch({ name: `metadata.emails.${index}.template` })
  const isInvitation = useIsInvitation({ templateId })

  return (
    <Stack>
      <TemplateSelect index={index}/>
      {isInvitation && <BatterySelect index={index}/>}
      <DateSelection index={index}/>
      <div className={styles.consequentEmailAction}>
        <Tooltip label='Add email' position='bottom-start'>
          <ActionIcon onClick={() => {
            insert(index + 1, {
              template: null,
              schedule: null
            })
          }}>
            <IconPlus/>
          </ActionIcon>
        </Tooltip>
        <ActionIcon onClick={() => remove(index)}>
          <IconMinus/>
        </ActionIcon>
      </div>
      {(getValues('metadata.emails') && index !== (getValues('metadata.emails').length - 1)) && <Divider size="sm" variant="dotted"/>}
    </Stack>
  )
}

function DateSelection ({ index }) {
  const { control, register } = useFormContext()
  const sendLater = useWatch({ control: control, name: `metadata.emails.${index}.schedule` })

  const {
    field: { onChange, onBlur, name, value }
  } = useController({
    name: `metadata.emails.${index}.schedule`,
    control: control,
    rules: { required: 'An email schedule must be selected' }
  })

  return (
    <Grid>
      <Grid.Col sm={12}>
        <Radio.Group
          label='When do you want to send this email?'
          value={value?.toString()}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
        >
          <Stack>
            <Radio value='0' label='Immediately' />
            <Radio value='1' label='X Days After Execution' />
          </Stack>
        </Radio.Group>
        <Error name={`metadata.emails.${index}.schedule`}/>
      </Grid.Col>
      {
        parseInt(sendLater) === 1 &&
        <Grid.Col span={12}>
          <label>Choose the amount of days
            <input type="text" {...register(`metadata.emails.${index}.after_execution`, {
              required: 'The amount of days after execution is required',
              pattern: {
                value: /^\d+$/,
                message: 'This must be a number'
              }
            })}/>
          </label>
          <Error name={`metadata.emails.${index}.after_execution`}/>
        </Grid.Col>
      }
    </Grid>
  )
}

function useIsInvitation ({ templateId }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (typeof templateId !== 'undefined' && templateId !== null) {
      axios.get(`api/v1/templates/${templateId}`)
        .then((response) => {
          setData(false)
          if (typeof response.data.category !== 'undefined') {
            setData(response.data.category.name === 'ProctorFree Invitation')
          }
        })
    }
  }, [templateId])

  if (data !== null) {
    return data
  }
}
