import { createSelector, createSlice } from '@reduxjs/toolkit'
import { shallowEqual } from 'react-redux'

const defaultCardState = (id, index) => {
  return {
    order: index
  }
}

const initialState = {
  openedCards: {},
  modals: {
    rezview: null,
    flags: null
  }
}

export const detailViewSlice = createSlice({
  name: 'detailView',
  initialState: initialState,
  reducers: {
    openCard: (state, action) => {
      const { id } = action.payload
      state.openedCards[id] = defaultCardState(id, Object.keys(state.openedCards).length)
    },
    closeCard: (state, action) => {
      const { id } = action.payload
      delete state.openedCards[id]
    },
    toggleCard: (state, action) => {
      const { id } = action.payload
      if (state.openedCards[id]) {
        delete state.openedCards[id]
      } else {
        state.openedCards[id] = defaultCardState(id, Object.keys(state.openedCards).length)
      }
    },
    focusCard: (state, action) => {
      const { id } = action.payload
      const focusedCardOrder = state.openedCards[id].order
      const highestCardOrder = Math.max(...Object.values(state.openedCards).map(card => card.order))

      if (focusedCardOrder === highestCardOrder) return

      for (const card of Object.values(state.openedCards)) {
        if (card.order > focusedCardOrder) {
          card.order -= 1
        }
      }

      state.openedCards[id].order = highestCardOrder + 1
    },
    openModal: (state, action) => {
      const { modal, id } = action.payload // TODO: once we start using react components instead of rendering the html content, pass component to render here instead of id
      console.log('open modal', modal, id)
      state.modals[modal] = id
    },
    closeModal: (state, action) => {
      const { modal } = action.payload
      state.modals[modal] = null
    }
  }
})

const openedCards = (state) => state.detailView.openedCards
const modalIds = (state) => state.detailView.modals

export const selectCard = createSelector(
  [openedCards, (state, id) => id],
  (opened, id) => {
    return opened[id]
  }
)

export const selectAllOpenedKeys = createSelector(
  [openedCards],
  (opened) => {
    return Object.keys(opened)
  },
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
)

export const isFocused = createSelector(
  [openedCards, (state, id) => id],
  (opened, id) => {
    const order = opened[id].order
    const maxOrder = Math.max(...Object.values(opened).map(card => card.order))
    if (order === maxOrder) {
      return true
    }
    return false
  }
)

export const selectModalValue = createSelector(
  [modalIds, (state, id) => id],
  (modals, id) => {
    return modals[id]
  }
)

export const { openCard, closeCard, focusCard, toggleCard, openModal, closeModal } = detailViewSlice.actions

export default detailViewSlice.reducer
