/* eslint react/prop-types: 0 */
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React, { useCallback, useEffect, useState, memo } from 'react'
import { Box, Button, Flex, Group, LoadingOverlay, ScrollArea, SimpleGrid, Stack, Switch } from '@mantine/core'
import { IconCaretDown, IconSend } from '@tabler/icons-react'
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, useEditor } from '@tiptap/react'
import { RichTextEditor } from '@mantine/tiptap'
import { NewDropdown } from '../../../core/NewDropdown'
import { useGetApplicantTagsQuery, useGetCommentsQuery, usePostCommentMutation } from '../../../../redux/query/hire/commentsApi.slice'
import { Comment } from './DiscussionComment'
import { StatusContext } from './DiscussionContexts'
import { useScrollIntoView } from '@mantine/hooks'
dayjs.extend(duration)

export function NewDiscussion ({ applicantId }) {
  const { data: comments, isFetching: processingFetchComments } = useGetCommentsQuery(applicantId)
  const [postComment] = usePostCommentMutation()
  const [hideUnpinnedFromView, setHideUnpinnedFromView] = useState(false)
  const { data: applicantTags } = useGetApplicantTagsQuery(applicantId) ?? []
  const [postedNewComment, setPostedNewComment] = useState(false)
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView({ onScrollFinish: () => setPostedNewComment(false) })

  useEffect(() => {
    if (postedNewComment && !processingFetchComments) {
      scrollIntoView({
        alignment: 'start'
      })
    }
  }, [postedNewComment, processingFetchComments, scrollIntoView])

  const post = useCallback((content, pinned, callback) => {
    if (content.length === 0) return
    setDisableSend(true)
    postComment({ applicantId: applicantId, content: content, pinned: pinned ? 1 : 0 })
      .unwrap()
      .then(() => {
        setDisableSend(false)
        setPostedNewComment(true)
        callback()
      })
  }, [applicantId, postComment])

  const [disableSend, setDisableSend] = useState(false)

  return (
    <Box>
      <SimpleGrid columns={1}>
        <StatusContext.Provider value={{ disableSend: setDisableSend, isFetchingComments: processingFetchComments }}>
          {comments &&
            <ScrollArea.Autosize mah='25rem' offsetScrollbars viewportRef={scrollableRef}>
              {comments.filter(c => hideUnpinnedFromView ? c.pinned : true).map((comment, index) => {
                const date = dayjs(comment.created_at)
                let isSubComment = false
                const isBotComment = !comment.account
                if (comments[index - 1]?.account && !isBotComment) {
                  const prevComment = comments[index - 1]
                  if (dayjs(prevComment.created_at).fromNow() === date.fromNow() ||
                  dayjs.duration(date.diff(prevComment.created_at)).asMinutes() < 5) isSubComment = true
                }
                return <Comment
                  key={comment.id}
                  comment={comment}
                  isBotComment={isBotComment}
                  isSubComment={isSubComment}
                  tagList={applicantTags}
                  />
              })}
              <div ref={targetRef} />
            </ScrollArea.Autosize>
          }
          <ControlBar onSend={post} disableSend={disableSend} hideUnpinnedFromView={hideUnpinnedFromView } setHideUnpinnedFromView={setHideUnpinnedFromView} />
        </StatusContext.Provider>
      </SimpleGrid>
    </Box>
  )
}

export function CommentEditor ({ content, onSubmit: handleSubmit, disabled = false, clearOnSubmit }) {
  const editor = useEditor({
    content: content,
    autofocus: 'end',
    editable: !disabled,
    extensions: [
      StarterKit
    ],
    editorProps: {
      handleKeyDown: (editor, ev) => {
        if ((ev.key === 'Enter' && !ev.shiftKey)) {
          ev.preventDefault()
          submit()
          return true
        }
      }
    }
  }, [])

  const submit = useCallback(() => {
    if (editor.getText().length === 0) return
    handleSubmit(editor.getHTML(), () => clearOnSubmit && editor.commands.clearContent())
  }, [handleSubmit, editor, clearOnSubmit])

  return (
    <Flex direction='column' justify='flex-end'>
      <RichTextEditor
        styles={{
          typographyStylesProvider: { paddingLeft: 0, marginBottom: 0 }
        }}
        editor={editor}
        >
        {editor && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 0 }}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Link />
            </RichTextEditor.ControlsGroup>
          </BubbleMenu>
        )}
        <RichTextEditor.Content />
      </RichTextEditor>
      <Group justify='space-between' style={{ userSelect: 'none' }}>
        <Box fz='xs' c='dimmed'>Press Enter to submit</Box>
        <Box fz='xs' c='dimmed'>Press Shift + Enter to create a new line</Box>
      </Group>
    </Flex>
  )
}

const ControlBar = memo(function ControlBar ({ content = '', disableSend, onSend: post, withOptionsDropdown = true, hideUnpinnedFromView, setHideUnpinnedFromView }) {
  const [defaultPinned, setDefaultPinned] = useState(true)

  const handleSubmit = useCallback((content, callback) => {
    console.log(callback)
    post(content, defaultPinned, callback)
  }, [post, defaultPinned])

  const withOptionsStyles = withOptionsDropdown
    ? {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    : {}

  return (
    <Group align='center' wrap='nowrap' w='100%'>
      <Box style={{ flexGrow: 1 }} pos='relative'>
        <LoadingOverlay loaderProps={{ type: 'bars', mb: 'sm' }} visible={disableSend} />
        <CommentEditor content={content} onSubmit={handleSubmit} disabled={disableSend} clearOnSubmit />
      </Box>
      <Button.Group mb='md'>
        <Button onClick={handleSubmit} disabled={disableSend} style={withOptionsStyles}>
          <IconSend />
        </Button>
        {withOptionsDropdown &&
        <NewDropdown target={<Button px={0} variant='light' disabled={disableSend} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}><IconCaretDown /></Button>}>
          <Stack p='xs'>
            <Switch label='Automatically pin comments' checked={defaultPinned} onChange={event => setDefaultPinned(event.currentTarget.checked)} />
            <Switch label='Hide unpinned comments from view' checked={hideUnpinnedFromView} onChange={event => setHideUnpinnedFromView(event.currentTarget.checked)} />
          </Stack>
        </NewDropdown>
        }
      </Button.Group>
    </Group>
  )
})
