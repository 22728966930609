import { ActionIcon, Anchor, Box, Button, Collapse, Divider, NavLink, ScrollArea } from '@mantine/core'
import { useDisclosure, useIsFirstRender } from '@mantine/hooks'
import React, { memo, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { IconCaretDownFilled, IconCaretUpFilled, IconChevronRight } from '@tabler/icons-react'
import { createInitialDropdownState } from './NewDropdownState'
import PropTypes from 'prop-types'
import * as styles from './Dropdown.module.scss'

const ScrollViewportContext = React.createContext(null)

const DEFAULTS = {
  mah: 350
}

export const DropdownListContent = memo(function DropdownListContent ({ items, keyPrefix = null, itemProps, mah = DEFAULTS.mah, children, extrasAlwaysOpen = false }) {
  const defaultOpenedItemKeys = items.filter(item => item.metadata?.defaultOpened).map(item => getItemKey(item, keyPrefix))
  const viewport = useRef(null)

  const [state, dispatch] = useReducer(dropdownListReducer, { openedMenus: defaultOpenedItemKeys }, createInitialDropdownState)
  const toggleMenu = useCallback((key) => {
    dispatch({ type: 'toggle-menu', key: key })
  }, [dispatch])

  return (
    <>
    <ScrollArea.Autosize mah={mah} type='auto' viewportRef={viewport}>
      <ScrollViewportContext.Provider value={viewport}>
      {items.map((item) => {
        const key = getItemKey(item, keyPrefix)
        return (
          <DropdownItem key={key} item={item} isOpen={state.openedMenus.includes(key)} onOpenToggle={toggleMenu} {...itemProps} />
        )
      })}
      </ScrollViewportContext.Provider>
    </ScrollArea.Autosize>
    {!!children && (!extrasAlwaysOpen
      ? (
      <CollapsibleDrawer>
        {children}
      </CollapsibleDrawer>
        )
      : (
        <Box p='xs'>
          {children}
        </Box>
        ))
    }
    </>
  )
})

const DropdownItem = memo(function DropdownItem ({ item, row = null, isOpen, onOpenToggle }) {
  const key = getItemKey(item)
  const itemRef = useRef(null)
  const viewport = useContext(ScrollViewportContext)
  const isFirstRender = useIsFirstRender()
  const itemProps = useMemo(() => {
    let _itemProps = { ...item }
    if (row) {
      if (item.metadata) {
        if (item.metadata.visible && item.metadata.visible(row) === false) {
          return null
        }
      }
      if (item.onClick) {
        _itemProps.onClick = () => item.onClick(row.id, row)
      }
      if (typeof item.href === 'function') {
        _itemProps.href = item.href(row.id)
      }
    }

    delete _itemProps.metadata

    if (item.metadata) {
      if (!item.href || item.component === Button) {
        _itemProps = {
          rightSection: item.metadata.items?.length > 0 && <IconChevronRight />,
          variant: 'discreet',
          ..._itemProps
        }
      }
    }

    if (item.href) {
      _itemProps = {
        component: Anchor,
        color: 'blue',
        variant: 'discreet',
        ..._itemProps
      }
    }

    return _itemProps
  }, [item, row])

  const subItems = item.metadata?.items ?? []

  useEffect(() => {
    const SCROLL_DELAY = 150 // needed to wait for DOM to update
    if (isOpen && itemRef.current && viewport.current && !isFirstRender) {
      setTimeout(() => {
        const itemTop = itemRef.current.offsetTop
        viewport.current.scrollTo({ top: itemTop, behavior: 'smooth' })
      }, SCROLL_DELAY)
    }
  }, [isOpen, viewport, isFirstRender])

  return (
    <NavLink
      ref={itemRef}
      label={item.label}
      childrenOffset={0}
      opened={isOpen}
      onChange={() => onOpenToggle(key)}
      className={styles.listItem}
      {...itemProps}
      active={isOpen}
      >
      {subItems.length > 0 &&
        <DropdownListContent items={subItems} row={row} keyPrefix={key} />
      }
    </NavLink>
  )
})

const CollapsibleDrawer = memo(function CollapsibleDrawer ({ children }) {
  const [isCollapsed, { toggle }] = useDisclosure(true)
  const CollapsibleButton = useMemo(() => (
    <ActionIcon onClick={toggle} size='md'>
      {isCollapsed ? <IconCaretDownFilled /> : <IconCaretUpFilled />}
    </ActionIcon>
  ), [isCollapsed, toggle])

  return (
    <>
      <Divider label={CollapsibleButton} />
      <Collapse in={!isCollapsed}>
        <Box p='xs'>
          {children}
        </Box>
      </Collapse>
    </>
  )
})

function getItemKey (item, keyPrefix) {
  const key = item.id || item.label.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()
  return keyPrefix ? `${keyPrefix}-${key}` : key
}

const dropdownListReducer = (state, action) => {
  switch (action.type) {
    case 'open-menu':
      return {
        ...state,
        openedMenus: state.openedMenus.concat(action.key)
      }
    case 'close-menu':
      return {
        ...state,
        openedMenus: state.openedMenus.filter(key => key !== action.key)
      }
    case 'toggle-menu':
      if (state.openedMenus.includes(action.key)) {
        return {
          ...state,
          openedMenus: state.openedMenus.filter(key => key !== action.key)
        }
      } else {
        return {
          ...state,
          openedMenus: state.openedMenus.concat(action.key)
        }
      }
    default:
      return state
  }
}

DropdownListContent.propTypes = {
  items: PropTypes.array,
  keyPrefix: PropTypes.string,
  itemProps: PropTypes.object,
  mah: PropTypes.number,
  children: PropTypes.node,
  extrasAlwaysOpen: PropTypes.bool
}

DropdownItem.propTypes = {
  item: PropTypes.object,
  row: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpenToggle: PropTypes.func
}

CollapsibleDrawer.propTypes = {
  children: PropTypes.node
}
