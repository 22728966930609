import React from 'react'
import { Skeleton, Tabs } from '@mantine/core'
import {
  IconClipboardText,
  IconEdit,
  IconInfoCircle,
  IconSettingsAutomation,
  IconTool
} from '@tabler/icons-react'
import ErrorLoading from '../../core/ErrorLoading'
import { useGetCycleQuery } from '../../../redux/query/hire/cyclesApi.slice'
import { CycleStageReplacementAppExternalWrapper } from './invites/stages/CycleStageReplacementApp'
import CycleListDisplay from './CycleListDisplay'

/**
 * Component that displays information about a cycle with actions and tabs that link to different management pages.
 * @param {object} props
 * @param {string|number} props.id The id of the cycle.
 * @param {string} props.selected The tab that should be highlighted by default.
 * @returns {React.JSX.Element}
 */
function CycleManagementDetail ({ id, selected }) {
  const { data: cycle, error, isLoading } = useGetCycleQuery(id)

  if (error) {
    return <ErrorLoading />
  }

  if (isLoading) {
    return <>
      <Skeleton height={25} width={350} mb='sm' />
      <Skeleton height={25} width={100} mb='sm' />
      <Skeleton height={25} width={350} mb='xs'/>
      <Skeleton height={25} width={350} mb='md' />
      <Skeleton height={25} mb='lg' />
    </>
  }

  const tabs = {
    edit: `/hire/report-results/${id}/edit`,
    specs: `/hire/report-results/${id}/specs`,
    details: `/cycles/${id}/cycleDetails`,
    batteries: `/hire/cycles/${id}/batteries`,
    triggers: `/hire/cycles/${id}/triggers`
  }

  return (
    <>
      <CycleListDisplay cycle={cycle} showActions={true} />
      <Tabs
        radius='md'
        variant='default'
        mb='xl'
        value={tabs[selected]}
        /* eslint-disable-next-line no-return-assign */
        onChange={value => window.location.href = value}
      >
        <Tabs.List>
          <Tabs.Tab value={tabs.edit} color='yellow.6' leftSection={<IconEdit size='1rem'/>}>Edit</Tabs.Tab>
          <Tabs.Tab value={tabs.specs} color='green.6' leftSection={<IconTool size='1rem'/>}>Specs</Tabs.Tab>
          <Tabs.Tab value={tabs.details} color='green.6' leftSection={<IconInfoCircle size='1rem'/>}>Details</Tabs.Tab>
          <Tabs.Tab value={tabs.batteries} color='blue.6' leftSection={<IconClipboardText size='1rem'/>}>Batteries</Tabs.Tab>
          <Tabs.Tab value={tabs.triggers} color='violet.6' leftSection={<IconSettingsAutomation size='1rem'/>}>Triggers</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <CycleStageReplacementAppExternalWrapper cycleId={id} />
    </>
  )
}

export default React.memo(CycleManagementDetail)
