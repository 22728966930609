import dayjs from 'dayjs'

/**
 * This function allows to format a date using dayjs.
 * @param {?string} date A date as string.
 * @param {string} [format] Optional format. Defaults to "MM-DD-YYYY".
 * @returns {string} The date in the given format.
 */
export function format (date, format = 'MM-DD-YYYY') {
  return dayjs(date).format(format)
}
