/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Anchor, Text } from '@mantine/core'
import { randomId } from '@mantine/hooks'
import {
  IconArchive,
  IconBriefcase,
  IconLock,
  IconLockOpen,
  IconMail,
  IconPencil,
  IconTableShortcut,
  IconTrash,
  IconUsers
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useOrganizations } from '../../util/OrganizationHooks';
import { formatToOptions } from '../../../js/util/DataUtil';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../core/ReactTable/paramsSlice';
import { useGetUsersQuery } from './UsersApi';
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

export function useUsersQuery (namespace, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetUsersQuery(queryParams, { skip })
  return [collection ?? null, querying]
}

const defaultFilters = { roles: ['ROLE_PORTAL_USER', 'ROLE_PORTAL_CLIENT', 'ROLE_ADMIN', 'ROLE_DEVELOPER'], active: '1' }
const defaultHiddenColumns = []

export function useUserTable (setUserToDelete, access) {
  return {
    tableActions: useUserTableActions(),
    columns: useUserColumns(),
    actions: useUserActions(setUserToDelete, access),
    filters: useUserFilters(),
    defaultFilters: defaultFilters,
    defaultHiddenColumns: defaultHiddenColumns,
    searchable: true
  }
}

export function useUserFilters () {
  const organizations = useOrganizations()

  return useMemo(() => {
    const organizationOptions = formatToOptions(organizations)
    return [ // TODO more roles / roles enum
      {
        id: 'roles',
        label: 'Roles',
        leftSection: <IconUsers />,
        options: [{ label: 'Portal Viewer', value: 'ROLE_PORTAL_USER' }, { label: 'Portal Client', value: 'ROLE_PORTAL_CLIENT' }, { label: 'SDS Admin', value: 'ROLE_ADMIN' }, { label: 'Developer', value: 'ROLE_DEVELOPER' }, { label: 'AP User', value: 'ROLE_AP' }, { label: 'Skillbuilder User', value: 'ROLE_SB_USER' }, { label: 'Facilitator', value: 'ROLE_SB_FACILITATOR' }, { label: 'Location Admin', value: 'ROLE_SB_LOCATION_ADMIN' }, { label: 'Super Admin', value: 'ROLE_SB_SUPER_ADMIN' }, { label: 'TestPrep User', value: 'ROLE_TESTPREP' }],
        multiSelect: true
      },
      {
        id: 'organization',
        label: 'Organization',
        leftSection: <IconBriefcase />,
        options: organizationOptions
      },
      {
        id: 'locked',
        label: 'Locked',
        leftSection: <IconLock />,
        placeholder: 'Showing both',
        options: [{ label: 'Locked', value: '1' }, { label: 'Not Locked', value: '0' }]
      },
      {
        id: 'active',
        label: 'Active',
        leftSection: <IconArchive />,
        placeholder: 'Showing both',
        options: [{ label: 'Active', value: '1' }, { label: 'Inactive', value: '0' }]
      }
    ]
  }, [organizations])
}

export function useUserColumns () {
  return useMemo(
    () => [
      {
        Header: <Text>First Name</Text>,
        Cell: ({ cell: { value } }) => (
          <Text>{value}</Text>
        ),
        id: 'first_name',
        accessor: 'first_name',
        sortable: true
      },
      {
        Header: <Text>Last Name</Text>,
        Cell: ({ cell: { value } }) => (
          <Text>{value}</Text>
        ),
        id: 'last_name',
        accessor: 'last_name',
        sortable: true
      },
      {
        Header: <Text>Email</Text>,
        Cell: ({ cell: { value } }) => (
          <Text>{value}</Text>
        ),
        id: 'email',
        accessor: 'email',
        sortable: true
      },
      {
        Header: <Text>Organizations</Text>,
        id: 'organizations',
        accessor: 'organization_access',
        Cell: ({ cell: { value } }) => (
          _.map(value, organizationAccess => <Text key={randomId()}>{organizationAccess.organization.name}</Text>)
        )
      },
      {
        Header: <Text>Roles</Text>,
        id: 'roles',
        accessor: 'roles',
        Cell: ({ cell: { value } }) => (
          _.map(value, roles => <Text key={randomId()} >{roles.label}</Text>)
        )
      },
      {
        Header: <Text>Active</Text>,
        id: 'active',
        accessor: 'active',
        Cell: ({ cell: { value } }) => <Text>{value ? 'Active' : 'Inactive'}</Text>,
        sortable: true
      },
      {
        Header: <Text>Locked</Text>,
        id: 'locked',
        accessor: 'locked',
        Cell: ({ cell: { value } }) => <Text>{value ? 'Locked' : 'Not Locked'}</Text>
      },
      {
        Header: <Text>Last Activity</Text>,
        id: 'last_activity_at',
        accessor: 'last_activity_at',
        Cell: ({ cell: { value } }) => <Text>{value === undefined ? '' : dayjs(value).format('MMMM Do YYYY [at] hh:mma')}</Text>,
        sortable: true
      }
    ],
    []
  )
}

export function useUserTableActions (hasAdminAccess) {
  return useMemo(
    () => {
      return [
        {
          label: 'User Export',
          leftSection: <IconTableShortcut />,
          href: '/clients/users/export',
          metadata: {
            isGranted: !hasAdminAccess
          }
        }
      ].filter(item => item.metadata?.isGranted !== false)
    }, []
  )
}

const defaultTableRoleNames = ['ROLE_PORTAL_USER', 'ROLE_PORTAL_CLIENT', 'ROLE_ADMIN', 'ROLE_DEVELOPER']
const additionalAssumeTableRoleNames = ['ROLE_SB_FACILITATOR', 'ROLE_SB_LOCATION_ADMIN', 'ROLE_SB_SUPER_ADMIN']
const noAssumeTableRoleNames = ['ROLE_ADMIN', 'ROLE_DEVELOPER']

function hasAnyDefaultTableRoles (roles) {
  return (roles ?? []).some(elem => defaultTableRoleNames.includes(elem.name))
}

function hasAnyNoAssumeTableRoles (roles) {
  return (roles ?? []).some(elem => noAssumeTableRoleNames.includes(elem.name))
}

function hasAnySkillbuilderAssumeTableRoles (roles) {
  return (roles ?? []).some(elem => additionalAssumeTableRoleNames.includes(elem.name))
}

export function useUserActions (setUserToDelete, access) {
  return useMemo(
    () => {
      return [
        {
          component: Anchor,
          label: 'Edit',
          leftSection: <IconPencil />,
          href: (id) => `/clients/users/${id}/edit`,
          metadata: {
            visible: ({ roles }) => hasAnyDefaultTableRoles(roles)
          }
        },
        {
          label: 'Assume',
          leftSection: <IconUsers />,
          href: (id) => `/clients/users/${id}/assume`,
          metadata: {
            isGranted: access?.ADMIN ?? false,
            visible: ({ active, roles }) => (active === 1) && !hasAnyNoAssumeTableRoles(roles) && (hasAnyDefaultTableRoles(roles) || hasAnySkillbuilderAssumeTableRoles(roles))
          }
        },
        {
          label: 'Invite to HireScore',
          leftSection: <IconMail />,
          href: (id) => `/clients/users/${id}/invite`,
          metadata: {
            visible: ({ active }) => active !== 1
          }
        },
        {
          label: 'Unlock',
          leftSection: <IconLockOpen />,
          href: (id) => `/clients/users/${id}/unlock`,
          metadata: {
            visible: ({ locked }) => locked === 1
          }
        },
        {
          label: 'Delete',
          leftSection: <IconTrash />,
          onClick: (id) => setUserToDelete(id),
          variant: 'danger-subtle',
          color: 'black',
          metadata: {
            visible: ({ roles }) => hasAnyDefaultTableRoles(roles)
          }
        }
      ].filter(item => item.metadata?.isGranted !== false)
    }, [access, setUserToDelete]
  )
}
