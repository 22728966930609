function createInitialDropdownState (initialArg) {
  const blankState = {
    isOpen: false,
    isFrozen: false,
    disableHoverOpen: false,
    extraCollapsed: true,
    ...initialArg
  }

  return blankState
}

const DropdownStateUpdate = Object.freeze({
  Unfreeze: 'unfreeze',
  Open: 'open',
  Close: 'close',
  MouseEnter: 'mouse-enter',
  MouseLeave: 'mouse-leave'
})

function dropdownReducer (state, action) {
  switch (action.type) {
    case DropdownStateUpdate.Unfreeze: {
      if (!state.isFrozen) return state
      if (state.disableHoverOpen) return { ...state, isFrozen: false, isOpen: false }
      return { ...state, isFrozen: false }
    }

    case DropdownStateUpdate.Open: {
      if (state.isOpen && state.isFrozen) return state
      return { ...state, isOpen: true, isFrozen: true }
    }

    case DropdownStateUpdate.Close: {
      if (!state.isOpen) return state
      return { ...state, isOpen: false, isFrozen: false }
    }

    case DropdownStateUpdate.MouseEnter: {
      if (state.isFrozen || state.disableHoverOpen) return state
      return { ...state, isOpen: true }
    }

    case DropdownStateUpdate.MouseLeave: {
      if (state.isFrozen) return state
      return { ...state, isOpen: false }
    }

    default:
      console.error(`Unknown action type: ${action.type}`)
      return state
  }
}

export { createInitialDropdownState, DropdownStateUpdate, dropdownReducer }
