/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Center,
  Divider,
  Grid,
  List,
  Paper,
  Space,
  Stack,
  Textarea,
  Title,
  Transition,
  TypographyStylesProvider,
  Tooltip,
  CloseButton,
  Indicator
} from '@mantine/core';
import { useSpring, animated, to } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { QuestionMediaLocation } from '../../js/generated/enums/QuestionMediaLocation';
import { PDFQuestionAnswers, PublishedQuestionAnswers } from './PublishedQuestionAnswers';
import { QuestionType } from '../../js/generated/enums/QuestionType';
import { MediaType } from '../../js/generated/enums/MediaType';
import { useDispatch, useSelector } from 'react-redux';
import { selectPreviousHidingFromLogic, selectPreviousQuestionShowing, selectQuestionById } from './questionSlice';
import {
  BatchTransitionToQuestionComplete,
  selectPreviousQuestionAnswered
} from './respondentAnswersSlice';
import { useRespondentAnswerAdditionalComment } from './PublishedAnswerHooks';
import { previousQuestionAnswered, selectIsTransitionToTarget } from './assessmentsSlice';
import * as styles from '../layout/animations/styles.module.css'
import { toSvg } from 'html-to-image';
import {
  questionCaptured,
  questionData,
  questionLoaded,
  selectDoCapture,
  selectDoUpdateDimensions
} from './exportsSlice';

/**
 * @param questionId
 */
export const PublishedQuestion = memo(function PublishedQuestion ({ questionId }) {
  /**
   * @type {?PublishedQuestion}
   */
  const question = useSelector(state => selectQuestionById(state, questionId))
  const scrollRef = useAssessmentQuestionTransition(questionId, question)

  if (!question) {
    return <></>
  }
  const questionIsContentOnly = ((question.type === QuestionType.TextBlock) || (question.type === QuestionType.SectionHeader))
  // Note that hidden question text is not rendered and media is not loaded.
  return (
    <Transition
      mounted={question.show && !question.hideFromLogic}
      transition='fade'
      duration={2000}
      timingFunction='ease'
      keepMounted
    >
      {(transitionStyles) => (
        <Paper ref={scrollRef} shadow="sm" variant={(question.type === QuestionType.SectionHeader) ? 'q-header' : 'q-text'} style={transitionStyles} withBorder={!questionIsContentOnly}>
          <Stack>
            <PublishedQuestionCompetencies competencies={question.competencies}/>
            {
              (question.show || question.loadMedia) &&
              question.media &&
              (question.mediaLocation === QuestionMediaLocation.Top) &&
              <PublishedQuestionMedia media={question.media} location={QuestionMediaLocation.Top}/>
            }
            {question.show && <PublishedQuestionText questionContent={question.content} questionType={question.type}
                                                     questionNumber={question.showNumber ? question.number : null}
                                                     large={question.large}/>}
            {
              (question.show || question.loadMedia) &&
              question.media &&
              (question.mediaLocation === QuestionMediaLocation.Bottom) &&
              <PublishedQuestionMedia media={question.media} location={QuestionMediaLocation.Bottom}/>
            }
            {question.comment && <PublishedQuestionComment questionId={questionId} />}
            <PublishedQuestionAnswers question={question} />
          </Stack>
        </Paper>
      )}
    </Transition>
  )
})

function useAssessmentQuestionTransition (questionId, question) {
  const scrollRef = useRef()
  const dispatch = useDispatch()
  const isScrollingRef = useRef(false)
  const [scrollNextRender, setScrollNextRender] = useState(false)

  const assessmentId = question?.location.assessmentId

  const previousAnsweredOrSkipped = useSelector(state => selectPreviousQuestionAnswered(state, question?.previousQuestionId))
  const previousShowing = useSelector(state => selectPreviousQuestionShowing(state, question?.previousQuestionId))
  const previousHidingFromLogic = useSelector(state => selectPreviousHidingFromLogic(state, question?.previousQuestionId))
  const isTransitionToTarget = useSelector(state => selectIsTransitionToTarget(state, assessmentId, questionId))
  const previousShouldAnswer = !!question?.previousQuestionAnswerable

  console.debug('Rendering question', questionId, question, previousAnsweredOrSkipped)
  const previousResolved = (previousAnsweredOrSkipped || !previousShouldAnswer || previousHidingFromLogic) && previousShowing

  useEffect(() => {
    if (previousResolved && !!(question?.location) && !question.show) {
      dispatch(previousQuestionAnswered({ ...question.location, answerable: question.answerable, hideFromLogic: question.hideFromLogic, previousIsContent: !previousShouldAnswer, previousId: question.previousQuestionId }))
    }
  }, [previousResolved, previousShouldAnswer, question?.show, question?.location, question?.hideFromLogic, question?.previousQuestionId, question.answerable, dispatch])

  useEffect(() => {
    if (isTransitionToTarget && question?.show && !question.hideFromLogic) {
      if (scrollNextRender) {
        if (!isScrollingRef.current) {
          console.debug('Starting transition to target.', questionId, isScrollingRef.current, scrollNextRender)
          isScrollingRef.current = true
          window.setTimeout(() => {
            scrollRef.current?.scrollIntoView(
              {
                behavior: 'smooth',
                block: ((question.type === QuestionType.TextBlock) || (question.type === QuestionType.SectionHeader)) ? (question.previousQuestionOnPageIsContentOnly ? 'center' : 'start') : 'center'
              }
            )
            window.setTimeout(() => {
              isScrollingRef.current = false
              dispatch(BatchTransitionToQuestionComplete({ questionId, assessmentId }))
            }, 1000)
          }, 250)
        }
      } else {
        console.debug('Queuing transition to target for next render.', questionId, isScrollingRef.current, scrollNextRender, scrollRef.current)
        setScrollNextRender(true)
      }
    } else {
      if (scrollNextRender) {
        setScrollNextRender(false)
      }
    }
  }, [isTransitionToTarget, question?.show, question?.hideFromLogic, scrollNextRender, assessmentId, question.previousQuestionOnPageIsContentOnly, question.type, questionId, dispatch])

  return scrollRef
}

/**
 * @param {PublishedQuestionCompetency[]|null} competencies
 */
const PublishedQuestionCompetencies = memo(function PublishedQuestionCompetencies ({ competencies }) {
  return (
    <>
      {
        !!(competencies?.length) &&
        <Stack>
          <Title order={2}>Competenc{competencies.length > 1 ? 'ies' : 'y'}</Title>
          <List size='xl'>
            {competencies.map(elem => <List.Item key={elem.id}>{elem.name}</List.Item>)}
          </List>
          <Space h='md' />
        </Stack>
      }
    </>
  )
})

/**
 * @param {string} questionContent
 * @param {QuestionType} questionType
 * @param {?int} questionNumber Null if numbers hidden for assessment or not numbered
 * @param {boolean} large True if center block
 * @param {object} paperParams params passed to paper wrapper around text, used in printToPDF
 */
export const PublishedQuestionText = memo(function PublishedQuestionText ({ questionContent, questionType, questionNumber = null, large = true, paperParams = {} }) {
  switch (questionType) {
    case QuestionType.SectionHeader: {
      return (
        <Paper variant='q-header-block' { ...paperParams }>
          <PublishedQuestionTextContent questionContent={questionContent} />
        </Paper>
      )
    }
    case QuestionType.TextBlock: {
      return (
        <Paper variant='q-block' { ...paperParams }>
          <PublishedQuestionTextContent questionContent={questionContent} />
        </Paper>
      )
    }
    default: {
      return (
        <Paper variant='q-block' { ...paperParams }>
          <Stack>
            <Grid align='center' columns={24}>
              {(!!questionNumber) && <Grid.Col span={large ? 2 : 4}><Center><Avatar color="dark.6" variant='outline' radius="xl" size='lg'>{questionNumber}</Avatar></Center></Grid.Col>}
              <Grid.Col span={questionNumber ? (large ? 22 : 20) : 24}>
                <PublishedQuestionTextContent questionContent={questionContent} />
              </Grid.Col>
            </Grid>
            <Divider my="md" />
          </Stack>
        </Paper>
      )
    }
  }
})

export function PublishedQuestionTextContent ({ questionContent }) {
  return (
    <PublishedQuestionTextStyles>
      <Center>
        <Box miw='100%' mih='100%' dangerouslySetInnerHTML={{ __html: questionContent }} />
      </Center>
    </PublishedQuestionTextStyles>
  )
}

function PublishedQuestionTextStyles ({ children }) {
  return (
    <TypographyStylesProvider miw='100%' mih='100%' mt={0} mb={0} mr={0} ml={0} pl={0} pr={0} pt={0} pb={0}>
      {children}
    </TypographyStylesProvider>
  )
}

function PublishedQuestionComment ({ questionId }) {
  const [additionalComment, setAdditionalComment] = useRespondentAnswerAdditionalComment(questionId)
  return (
    <>
      <Space h='md' />
      <Textarea
        label='Comments'
        placeholder='Feel free to leave any additional comments for review'
        value={additionalComment}
        onChange={(event) => setAdditionalComment(event.currentTarget.value)}
        size='xl'
        variant='interview'
      />
    </>
  )
}

const PublishedQuestionMedia = memo(function PublishedQuestionMedia ({ media, location }) {
  return (
    <>
      {location === QuestionMediaLocation.Bottom && <Space h='md' />}
      <Center>
        {media.type === MediaType.Image
          ? (
            <PublishedQuestionImage
              media={media}
            />
            )
          : (
            <video width={media.width ?? 640} height={media.height ?? 360} controls>
              <source src={media.link} type="video/mp4"/>
            </video>
            )
        }
      </Center>
      {location === QuestionMediaLocation.Top && <Space h='md' />}
    </>
  )
})

const PublishedQuestionImage = memo(function PublishedQuestionImage ({ media }) {
  const [loaded, setLoaded] = useState(false)
  const domTarget = useRef(null)
  const canResetRef = useRef(false)
  const [canReset, setCanReset] = useState(false)
  const [hovered, setHovered] = useState(false)
  const didResetRef = useRef(false)
  const resetOffset = useRef([0, 0]) // This is incredibly hacky and doesn't reset the zoom (would need parallel implementation) but it works. Something about this example usage of either the spring api or the use gesture api is lacking in terms of being able to reset the queue of values sent by useGesture.
  const springConfig = useCallback(() => {
    return {
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
      config: { mass: 5, tension: 350, friction: 60 }
    }
  }, [])
  const [{ x, y, zoom, scale }, api] = useSpring(
    springConfig
  )

  useGesture(
    {
      onClick: () => {
        console.debug('Called gesture click!')
      },
      onDrag: (state) => {
        const { active, canceled, offset: [x, y], first, movement: [mx, my], lastOffset: [lx, ly], distance: [dx, dy] } = state
        console.debug('Called on drag', active, x, y, canceled, state)
        const postReset = didResetRef.current
        if (postReset) {
          console.debug('Is post reset!', first, mx, my, dx, dy, lx, ly, state.distance[0], state.distance[1])
          didResetRef.current = false
          resetOffset.current = [x, y]
        } else {
          console.debug('Not reset.', first, mx, my, dx, dy, lx, ly, state.distance[0], state.distance[1])
        }
        api({ x: x - resetOffset.current[0], y: y - resetOffset.current[1] })
        if (!canResetRef.current && !postReset) {
          canResetRef.current = true
          setCanReset(true)
        }
      },
      onPinch: ({ offset: [d, a] }) => {
        api({ zoom: d / 600 })
        if (!canResetRef.current) {
          canResetRef.current = true
          setCanReset(true)
        }
      },
      onMove: ({ dragging }) =>
        !dragging &&
        api({
          scale: 1.1
        }),
      onHover: ({ hovering }) => {
        if (!hovering) {
          api({ scale: 1 })
        }
        setHovered(hovering)
      },
      onWheel: ({ event, offset: [, y] }) => {
        event.preventDefault()
        api({ zoom: -y / 600 })
        if (!canResetRef.current) {
          canResetRef.current = true
          setCanReset(true)
        }
      }
    },
    { target: domTarget, eventOptions: { passive: false } }
  )

  const resetImage = () => {
    console.debug('Called reset image - setting api values')
    api({
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
      immediate: true
    })
    console.debug('Called reset image - setting reset state')
    didResetRef.current = true
    setCanReset(false)
    canResetRef.current = false
    setHovered(false)
  }

  return (
    <Box style={{ position: 'relative' }}>
      <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'var(--mantine-color-gray-4)', width: '3rem', height: '3rem' }} >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <CloseButton
            aria-label='Reset Image'
            title='Reset Image'
            iconSize='1rem'
            style={{ position: 'absolute', top: '-1rem', right: '-1rem', zIndex: 3 }}
            onClick={resetImage}
          />
        </div>
      </Box>
      <Tooltip label='Scroll to Zoom' opened={hovered}>
        <animated.div
          ref={domTarget}
          className={styles.card}
          style={{
            transform: 'perspective(600px)',
            x: x,
            y: y,
            scale: to([scale, zoom], (s, z) => Math.max(s + z, -s + Math.abs(z), 0.5))
          }}
        >
          {canReset && (<CloseButton
            aria-label='Reset Image'
            title='Reset Image'
            iconSize='1rem'
            style={{ position: 'absolute', right: '-2rem', top: '-2rem' }}
            onClick={resetImage}
          />)}
          <img
            src={media.link}
            alt={loaded ? 'This image could not be loaded correctly. Please try refreshing the page.' : 'Loading...'}
            height={media.height ?? 360}
            width={media.width ?? 640}
            style={{ objectFit: 'contain', borderRadius: 0, pointerEvents: 'none' }}
            onLoad={() => setLoaded(true)}
            onError={() => setLoaded(true)}
          />
        </animated.div>
      </Tooltip>
    </Box>
  )
})

function reduxRenderToSVG (dispatch, element, questionId, questionMetrics, retried = false) {
  console.debug('Starting question render to svg.', questionId, element.current, retried, window.devicePixelRatio)
  toSvg(element.current, { backgroundColor: '#FFF', cacheBust: true })
    .then(function (dataUrl) {
      console.debug('Question rendered to svg.', questionId, questionMetrics, dataUrl)
      dispatch(questionCaptured({ data: dataUrl, error: null, id: questionId }))
    })
    .catch(function (error) {
      console.error('Render to svg error for question.', questionId, error)
      if (!retried) {
        if (element.current) { // Null if component unmounted due to cancel.
          console.warn('Retrying render to svg once for question id.', questionId)
          reduxRenderToSVG(dispatch, element, questionId, questionMetrics, true)
        } else {
          console.debug('Not retrying render to svg - node is null. Render cancelled?', questionId)
        }
      } else {
        console.error(
          'Not retrying question svg render. Setting flag true as render data for question id - question will be missing from output.',
          questionId,
          error
        )
        dispatch(questionCaptured({ data: true, error: true, id: questionId }))
      }
    })
}

/**
 * Note: for use in printing to PDF only.
 * @param questionId
 */
export const PDFQuestion = memo(function PDFQuestion ({ questionId, fullColor = false }) {
  const nodeRef = useRef()
  const measuredRef = useRef(false)
  const capturedRef = useRef(false)
  /**
   * @type {?PublishedQuestion}
   */
  const question = useSelector(state => selectQuestionById(state, questionId))
  const doUpdateDimensions = useSelector(state => selectDoUpdateDimensions(state))
  const doCapture = useSelector(state => selectDoCapture(state, questionId))
  const dispatch = useDispatch()
  const questionIndex = question?.location.indexInAssessment ?? 0
  const [mediaLoaded, setMediaLoaded] = useState(question?.media?.type !== MediaType.Image)

  useEffect(() => {
    console.debug('Do update dimensions effect mounted.', questionId, doUpdateDimensions, nodeRef.current)
    if (doUpdateDimensions && !measuredRef.current && nodeRef.current?.offsetWidth && (mediaLoaded !== false)) {
      console.debug('Node ref for pdf question setting dimensions', questionId, nodeRef.current?.offsetTop, nodeRef.current?.offsetHeight, nodeRef.current?.offsetWidth)
      measuredRef.current = true
      dispatch(questionLoaded({
        id: questionId,
        width: nodeRef.current?.offsetWidth,
        height: nodeRef.current?.offsetHeight,
        offset: nodeRef.current?.offsetTop,
        index: questionIndex
      }))
    } else if (doUpdateDimensions && !measuredRef.current && !nodeRef.current?.offsetWidth && (mediaLoaded !== false)) {
      console.error(
        'Node ref was not initialized for pdf question when should set dimensions - question will be missing from output.',
        questionId, mediaLoaded, nodeRef.current?.offsetTop, nodeRef.current?.offsetHeight, nodeRef.current?.offsetWidth
      )
      dispatch(questionData({ width: 1, height: 1, offset: 1, index: questionIndex, data: true, error: true, id: questionId }))
    }
  }, [doUpdateDimensions, questionId, questionIndex, mediaLoaded, dispatch])

  useEffect(() => {
    if (doCapture && !capturedRef.current) {
      console.debug('Will capture question after timeout.', questionId)
      capturedRef.current = true
      window.setTimeout(() => {
        console.debug('Timeout capturing question.', questionId)
        reduxRenderToSVG(dispatch, nodeRef, questionId, { questionIndex })
      }, 10) // Using setTimeout allows the browser to handle mouse events in the meantime instead of blocking main thread. [without slowing down the process]
    }
  }, [doCapture, questionId, questionIndex, dispatch])

  const paperParams = useMemo(() => {
    return fullColor ? {} : { bg: '#FFF', style: { shadow: 0, border: '4px solid #000000' } } // Was borderWidth: '5px'
  }, [fullColor])

  const childPaperParams = useMemo(() => {
    return fullColor ? {} : { bg: '#FFF', style: { shadow: 0 } }
  }, [fullColor])

  const onMediaLoad = useCallback((onLoadParam) => {
    console.debug('Setting media loaded.', onLoadParam, questionId)
    setMediaLoaded(true)
  }, [questionId])

  const onMediaError = useCallback((onErrorParam) => { // Note media error is not currently reported, it is sometimes resolved without issue.
    console.error('Setting media on error.', onErrorParam, questionId)
    setMediaLoaded(null)
  }, [questionId])

  if (!question) {
    return <></>
  }

  const questionIsContentOnly = ((question.type === QuestionType.TextBlock) || (question.type === QuestionType.SectionHeader))
  return (
    <div ref={nodeRef}>
      <Indicator color='green' size={30} offset={30} position='top-start' label='Logic' disabled={!question.showHasLogicIndicator}>
        <Paper shadow={fullColor ? 'sm' : 0} variant={(question.type === QuestionType.SectionHeader) ? 'q-header' : 'q-text'} withBorder={!questionIsContentOnly || !fullColor} { ...paperParams }>
          <Stack>
            <PublishedQuestionCompetencies competencies={question.competencies}/>
            {
              question.media &&
              (question.mediaLocation === QuestionMediaLocation.Top) &&
              <PDFQuestionMedia media={question.media} location={QuestionMediaLocation.Top} onLoad={onMediaLoad} onError={onMediaError}/>
            }
            {question.show && <PublishedQuestionText questionContent={question.content}
                                                     questionType={question.type}
                                                     questionNumber={question.showNumber ? question.number : null}
                                                     large={question.large}
                                                     paperParams={childPaperParams}/>}
            {
              question.media &&
              (question.mediaLocation === QuestionMediaLocation.Bottom) &&
              <PDFQuestionMedia media={question.media} location={QuestionMediaLocation.Bottom} onLoad={onMediaLoad} onError={onMediaError}/>
            }
            {question.comment && <PublishedQuestionComment questionId={questionId} />}
            <PDFQuestionAnswers question={question} />
          </Stack>
        </Paper>
      </Indicator>
    </div>
  )
})

const PDFQuestionMedia = memo(function PDFQuestionMedia ({ media, location, onLoad, onError }) {
  return (
    <>
      {location === QuestionMediaLocation.Bottom && <Space h='md' />}
      <Center>
        {media.type === MediaType.Image
          ? (
            <img
              src={media.link}
              alt='This image could not be loaded correctly. Please try refreshing the page.'
              height={media.height ?? 360}
              width={media.width ?? 640}
              crossOrigin='anonymous'
              style={{ objectFit: 'contain', borderRadius: 0 }}
              onLoad={onLoad}
              onError={onError}
            />
            )
          : (
            <Title c='red' fw={700}>{media.type} missing from export!</Title>
            )
        }
      </Center>
      {location === QuestionMediaLocation.Top && <Space h='md' />}
    </>
  )
})
