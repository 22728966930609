import React from 'react'
import { Anchor, Center, Table, Text } from '@mantine/core'
import * as styles from './HiringTemplateListDisplay.module.scss'

/**
 * This component renders a title and a table with information about a hiring template.
 * @param {object} props
 * @param {import("../../../models/hiringTemplateList.d").HiringTemplateList} props.hiringTemplate The hiring template.
 * @param {string} [props.variant] The size of the display. Acceptable options are "default" and "compact".
 * @returns {React.JSX.Element}
 */
function HiringTemplateListDisplay ({ hiringTemplate, variant = 'default' }) {
  return (
    <>
      <Anchor href={`/build/hiring-templates/${hiringTemplate.id}/specs`} size={variant === 'compact' ? 'sm' : 'xl'} truncate='end' fw={700} span>{hiringTemplate.name}</Anchor>
      <Table className={styles.table}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Organization</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Location</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Generic Job</Text></Center></Table.Th>
            <Table.Th><Center><Text fw={700} size={variant === 'compact' ? 'xs' : 'md'}>Specific Job</Text></Center></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{hiringTemplate.organization?.name ?? 'Any'}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{hiringTemplate.location?.name ?? 'Any'}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{hiringTemplate.generic_job?.name ?? 'Any'}</Text></Center></Table.Td>
            <Table.Td><Center><Text size={variant === 'compact' ? 'xs' : 'md'}>{hiringTemplate.specific_job ?? 'Any'}</Text></Center></Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  )
}

export default React.memo(HiringTemplateListDisplay)
