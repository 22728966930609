import React from 'react'
import HiringTemplateDetail from './HiringTemplateDetail'
import { useParams } from 'react-router-dom'

/**
 * This component allows to use the {@link HiringTemplateDetail} component using the URI to find the ID.
 * @param {object} props
 * @param {string} props.selected The tab that should be highlighted by default.
 * @returns {React.JSX.Element}
 */
export default function HiringTemplateDetailParams ({ selected }) {
  const { id } = useParams()

  return <HiringTemplateDetail id={id} selected={selected}/>
}
