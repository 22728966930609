/* eslint react/prop-types: 0 */

import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  Box,
  ScrollArea,
  ColorSwatch,
  Tooltip,
  ThemeIcon,
  Group,
  ActionIcon,
  Stack,
  Flex,
  Popover
} from '@mantine/core';
import * as DOMPurify from 'dompurify';
import { useClickOutside, useDisclosure, useHover, useMergedRef } from '@mantine/hooks';
import { Tag, tagFromId } from '../../../js/generated/enums/Tag';
import { IconMail, IconX } from '@tabler/icons-react';

const MAX_POPUP_HEIGHT = '15rem'
const DROPDOWN_OFFSET = 5

export function DetailViewNotesCell ({ value, row, mah }) {
  const { scores, tag, id } = row.original
  const COURTESY_LETTER_MODULE_ID = 52
  const courtesyLetter = scores.filter(elem => elem.module_id === COURTESY_LETTER_MODULE_ID)[0]?.score ?? null
  const [focused, { open, close }] = useDisclosure(false)
  const hasComments = value.length > 0
  const { ref, hovered } = useHover()
  const sizeRef = useRef(null)
  const [control, setControl] = useState(null)
  const [dropdown, setDropdown] = useState(null)
  const popoverOffset = -(sizeRef.current?.clientHeight + DROPDOWN_OFFSET) ?? 0

  const clickOutsideRef = useClickOutside(close, ['mouseup', 'touchend'], [control, dropdown])
  const mergedRef = useMergedRef(clickOutsideRef, setControl)

  const handleOpen = useCallback((event) => {
    if (window.getSelection().toString().length > 0) return
    event.stopPropagation()
    open()
  }, [open])

  const hoverStyles = useMemo(() => {
    return hovered
      ? {
          filter: 'brightness(0.93)',
          transition: 'filter'
        }
      : {
          filter: 'brightness(1)',
          transition: 'filter 100ms'
        }
  }, [hovered])

  if (!hasComments && !tag && !courtesyLetter) return null

  return (
    <Box mah={mah} onClick={e => e.stopPropagation()} style={{ overflow: 'hidden' }} ref={mergedRef} bg='inherit'>
    <Popover opened={focused} position='bottom' offset={popoverOffset} middlewares={{ inline: true }} width='target'>
      <Popover.Target ref={sizeRef}>
      <Box style={{ borderRadius: 4, ...hoverStyles }} ref={ref} bg='inherit'>
        <ScrollArea.Autosize mah={mah} style={{ cursor: 'default' }}>
          <Box onClick={handleOpen} p='xs'>
            <NotesCellTopItems tag={tag} courtesyLetter={courtesyLetter} mb={hasComments && 'sm'} />
            <NotesCell applicantId={id} comments={value} />
          </Box>
        </ScrollArea.Autosize>
      </Box>
      </Popover.Target>
      <Popover.Dropdown px={0} ref={setDropdown}>
        {/* Eventually, we can put the entire discussion app in here */}
        <Box>
          <Flex justify='space-between' align='center' gap='sm' px='xs' mb={hasComments && 'sm'}>
            <NotesCellTopItems tag={tag} courtesyLetter={courtesyLetter} />
            <ActionIcon onClick={close}><IconX /></ActionIcon>
          </Flex>
          <ScrollArea.Autosize mah={MAX_POPUP_HEIGHT} style={{ cursor: 'default', overflow: 'hidden', textWrap: 'wrap' }}>
            <Box px='xs'>
              <NotesCell applicantId={id} comments={value} px='xs' />
            </Box>
          </ScrollArea.Autosize>
        </Box>
      </Popover.Dropdown>
    </Popover>
    </Box>
  )
}

function NotesCell ({ applicantId, comments, props }) {
  return comments.length > 0 && (
    <Stack style={{ cursor: 'auto' }} gap='xs' {...props}>
    {comments.map((comment) => {
      return (
      <Box key={`${applicantId}-comment-${comment.id}`}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content) }}></div>
      </Box>
      )
    })}
  </Stack>
  )
}

function NotesCellTopItems ({ tag, courtesyLetter, ...props }) {
  return (
    <Group justify='center' {...props}>
    {!!tag && <ColorSwatch color={getTagColor(tagFromId(tag))} />}
    {!!courtesyLetter && <Tooltip label={courtesyLetter}><ThemeIcon size='md' variant='light' color='orange'><IconMail size='1.75rem' /></ThemeIcon></Tooltip>}
    </Group>
  )
}

export function getTagColor (tag) {
  switch (tag) {
    case Tag.Black:
      return 'rgba(0, 18, 25, 1)'
    case Tag.Blue:
      return 'rgba(0, 95, 155, 1)'
    case Tag.Green:
      return 'rgba(148, 230, 189, 1)'
    case Tag.Yellow:
      return 'rgba(238, 155, 0, 1)'
    case Tag.Red:
      return 'rgba(174, 32, 18, 1)'
    default:
      return 'rgba(255, 255, 255, 0)'
  }
}
