/* eslint-disable react/prop-types */
import { ActionIcon, Badge, Container, Divider, Flex, Group, Loader, RingProgress, Select, Stack, Text, TextInput, ThemeIcon, Tooltip } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconAt, IconBriefcase2, IconDeviceFloppy, IconHome, IconPhone, IconSchool, IconX } from '@tabler/icons-react'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { updateApplicant } from '../../../../js/api/applicant_repository'
import * as styles from './Info.module.scss'
import { formatToOptions } from '../../../../js/util/DataUtil'
import Content from '../../../layout/Content'

export function Info ({ applicant, editing, closeEdit, isAdmin, statuses }) {
  const [isSaving, setIsSaving] = useState(false)
  const form = useForm({
    initialValues: {
      first_name: applicant.first_name,
      middle_name: applicant.middle_name ?? '',
      last_name: applicant.last_name,
      email: applicant.contact_information.email,
      primary_phone_number: applicant.contact_information.primary_phone_number ?? '',
      secondary_phone_number: applicant.contact_information.secondary_phone_number ?? '',
      status: applicant.status.id.toString(),
      hired_at: applicant.hired_at && new Date(applicant.hired_at)
    },
    validate: {
      hired_at: (value, values) => (isStatusHired(values.status) && !value) ? 'Must have a hired date' : null,
      primary_phone_number: (value) => value && !value.match(/^[^\d]/g)?.length === 10 ? 'Must be a valid phone number' : null,
      secondary_phone_number: (value) => value && !value.match(/^[^\d]/g)?.length === 10 ? 'Must be a valid phone number' : null
    },
    transformValues: values => {
      return {
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        contact_information: {
          email: values.email,
          primary_phone_number: values.primary_phone_number,
          secondary_phone_number: values.secondary_phone_number
        },
        status: isNaN(parseInt(values.status)) ? undefined : parseInt(values.status),
        hired_at: values.hired_at && window.iso8601(values.hired_at)
      }
    }
  })

  const isStatusHired = (statusId) => _.find(statuses, { value: parseInt(statusId) })?.tier.name === 'hired'

  const baselineScore = useMemo(() => {
    const BASELINE_ID = 32
    const score = _.find(applicant.scores, { module_id: BASELINE_ID })?.percentile_score
    return score ? Math.round(score) : '-'
  }, [applicant])

  const baselineColor = useMemo(() => {
    if (baselineScore < 49) {
      return 'red'
    } else if (baselineScore < 69) {
      return 'orange'
    } else {
      return 'green'
    }
  }, [baselineScore])

  const handleSubmit = (values) => {
    setIsSaving(true)
    form.validate()

    const keys = Object.keys(form.values)
    const dirty = keys.filter(key => form.isDirty(key))
    const dirtyVals = _.pick(form.values, dirty)

    if (Object.keys(dirtyVals).length === 0) {
      setIsSaving(false)
      close()
      return
    }

    if (form.isDirty('email')) {
      if (!confirm('Changing the candidates\'s email address could impact test results. Would you like to proceed?')) {
        setIsSaving(false)
        return
      }
    }

    if (form.isDirty('primary_phone_number') || form.isDirty('secondary_phone_number')) {
      if (!confirm('Changing the candidate\'s phone number will remove consent to contact via SMS. Would you like to proceed?')) {
        setIsSaving(false)
        return
      }
    }

    updateApplicant(applicant.id, form.getTransformedValues(dirtyVals)).then(() => {
      showNotification({
        title: 'Success',
        message: 'Applicant was successfully updated',
        color: 'green'
      })

      values.hired_at = values.hired_at && new Date(values.hired_at)

      form.setInitialValues(values)
      form.resetDirty()

      setIsSaving(false)

      const event = new Event('applicant:updated')
      window.dispatchEvent(event)
      closeEdit()
    },
    error => {
      showNotification({
        title: 'Error',
        message: 'There was an error updating the applicant',
        color: 'red'
      })
      setIsSaving(false)
      console.error(error)
    })
  }

  const cancelEdit = () => {
    form.resetDirty()
    closeEdit()
  }

  const handleStatusChange = (val) => {
    form.getInputProps('status').onChange(val)
  }

  const statusTierColor = useMemo(() => getStatusTierColor(applicant.status.tier.name), [applicant])

  return (
    <Content>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {isAdmin && (
          <Flex justify='flex-end'>
          {editing && (
            <Group gap='xs'>
              <ActionIcon onClick={cancelEdit} color='gray' variant='light'><IconX /></ActionIcon>
              <ActionIcon
                type='submit'
                variant='filled'
                color='success'
                disabled={!form.isDirty()}
                >
                  {isSaving
                    ? <Loader color='white' size='xs' />
                    : <IconDeviceFloppy />
                  }
                </ActionIcon>
            </Group>
          )}
        </Flex>
        )}
        <Container>
          <Stack
            spacing='xs'
          >
          {editing
            ? (
              <>
                <Group wrap='nowrap'>
                  <TextInput
                    label='First'
                    required
                    {...form.getInputProps('first_name')}
                    />
                  <TextInput
                    label='Middle'
                    {...form.getInputProps('middle_name')}
                    />
                  <TextInput
                    label='Last'
                    required
                    {...form.getInputProps('last_name')}
                  />
                </Group>
                <TextInput
                  label='Email'
                  placeholder='applicant@email.com'
                  required
                  {...form.getInputProps('email')}
                />
                <Group wrap='nowrap' grow>
                  <TextInput label='Primary Phone' {...form.getInputProps('primary_phone_number')} />
                  <TextInput label='Secondary Phone' {...form.getInputProps('secondary_phone_number')} />
                </Group>
                <Divider />
                <Group wrap='nowrap' grow>
                  <Select
                    data={formatToOptions(statuses, { label: 'name', value: 'id' })}
                    label='Status'
                    onChange={handleStatusChange}
                    {...form.getInputProps('status')}
                  />
                  {isStatusHired(form.values.status) && (
                    <DateInput
                      label='Hired Date'
                      placeholder={new Date().toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}
                      onChange={val => form.getInputProps('hired_at').onChange(val)}
                      {...form.getInputProps('hired_at')}
                    />
                  )}
                </Group>
              </>
              )
            : (
              <>
                <Text
                  fz='lg'
                  fw='bold'
                  align='center'
                  >
                  {form.values.first_name} {form.values.middle_name} {form.values.last_name}
                </Text>
                <Group wrap='nowrap'>
                  <Stack spacing='0.25rem' className={[styles.applicantInfoContainer]}>
                    {(applicant.contact_information.city || applicant.contact_information.state) &&
                    <InfoRow
                      label='Current Location'
                      icon={<IconHome />}
                    >
                      <Text
                        fz='md'
                      >
                        {applicant.contact_information.city}
                        {(applicant.contact_information.state && applicant.contact_information.city) && ', '}
                        {applicant.contact_information.state}
                      </Text>
                    </InfoRow>
                    }
                    <InfoRow label='Current email' icon={<IconAt />}>
                      <Text
                        fz='md'
                      >
                        {form.values.email}
                      </Text>
                    </InfoRow>
                    {(form.values.primary_phone_number || form.values.secondary_phone_number) &&
                    <InfoRow label='Phone Number(s)' icon={<IconPhone />}>
                      <Stack gap='xs' justify='center'>
                        <Tooltip label='Primary number'>
                          <Text fz='md'>
                              {form.values.primary_phone_number}
                          </Text>
                        </Tooltip>
                        {form.values.secondary_phone_number &&
                          <Tooltip label='Secondary number'>
                            <Text fz='md'>
                              {form.values.secondary_phone_number}
                            </Text>
                          </Tooltip>
                        }
                      </Stack>
                    </InfoRow>
                    }
                    {applicant.work_history[0]?.title &&
                    <InfoRow label='Current/Most recent role' icon={<IconBriefcase2 />}>
                      <Text
                        fz='md'
                      >
                        {applicant.work_history[0].title}
                      </Text>
                    </InfoRow>
                    }
                    {applicant.education?.highest_degree?.answer &&
                    <InfoRow label='Highest level of education' icon={<IconSchool />}>
                      <Text
                        fz='md'
                      >
                        {applicant.education.highest_degree.answer}
                      </Text>
                    </InfoRow>
                    }
                  </Stack>
                    <Flex direction='column' justify='center' className={[styles.baselineWrapper]} >
                      <RingProgress
                        roundCaps
                        className={[styles.baseline]}
                        label={<Text fz='2rem' ta='center'>{baselineScore}</Text>}
                        sections={[
                          { value: baselineScore, color: baselineColor }
                        ]}
                      />
                      <Stack gap='xxs' justify='center' align='center'>
                        <Tooltip label='Current Status'>
                          <Badge
                            variant='outline'
                            color={statusTierColor}
                            size='md'
                            >
                            {applicant.status.name}
                          </Badge>
                        </Tooltip>
                        {(form.values.hired_at && isStatusHired(form.values.status)) && (
                          <Text
                            fz='sm'
                            ta='center'
                          >
                            Hired on: {new Date(form.values.hired_at).toLocaleDateString()}
                          </Text>
                        )}
                      </Stack>
                    </Flex>
                </Group>
              </>
              )}
          </Stack>
        </Container>
      </form>
    </Content>
  )
}

function InfoRow ({ label, children, icon }) {
  return (
  <Group wrap='nowrap'>
    <Tooltip label={label}>
      <ThemeIcon size='sm' variant='subtle' color='gray'>
        {icon}
      </ThemeIcon>
    </Tooltip>
      {children}
  </Group>
  )
}

function getStatusTierColor (tierName) {
  switch (tierName) {
    case 'hired':
      return 'success'

    case 'not recommended':
      return 'orange'

    case 'withdrew':
      return 'gray'

    case 'duplicate':
      return 'purple'

    case 'pending':
    default:
      return 'blue'
  }
}
