import { Box, Popover } from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import React, { memo, useCallback, useReducer, useRef, useState } from 'react'
import { createInitialDropdownState, dropdownReducer, DropdownStateUpdate } from './NewDropdownState'
import PropTypes from 'prop-types'

const DEFAULTS = Object.freeze({
  width: 'auto',
  openDelay: 300,
  closeDelay: 300
})

export const NewDropdown = memo(function NewDropdown ({
  target,
  mah = DEFAULTS.maxHeight,
  width = DEFAULTS.width,
  openDelay = DEFAULTS.openDelay,
  closeDelay = DEFAULTS.closeDelay,
  row,
  children,
  disableHoverOpen = false,
  ...props
}) {
  const [state, dispatch] = useReducer(dropdownReducer, { disableHoverOpen }, createInitialDropdownState)
  const timerRef = useRef(null)
  const [targetClick, setTargetClick] = useState(null)
  const [dropdownClick, setDropdownClick] = useState(null)

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)

      timerRef.current = null
    }
  }

  const handleClickOutside = useCallback(() => {
    dispatch({ type: DropdownStateUpdate.Close })
  }, [dispatch])

  const handleMouseEnter = useCallback(() => {
    clearTimer()

    timerRef.current = setTimeout(() => {
      dispatch({ type: DropdownStateUpdate.MouseEnter })
    }, openDelay)
  }, [dispatch, openDelay]);

  const handleMouseLeave = useCallback(() => {
    clearTimer()

    timerRef.current = setTimeout(() => {
      dispatch({ type: DropdownStateUpdate.MouseLeave })
    }, closeDelay)
  }, [dispatch, closeDelay]);

  const clickOutsideRef = useClickOutside(handleClickOutside, ['mouseup', 'touchend'], [targetClick, dropdownClick])

  const handleTargetClick = useCallback(() => {
    dispatch({ type: state.isFrozen ? DropdownStateUpdate.Unfreeze : DropdownStateUpdate.Open })
  }, [dispatch, state.isFrozen])

  return (
    <Box ref={clickOutsideRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Popover
        opened={state.isOpen}
        width={width}
        onKeyDown={event => event.key === 'Escape' && dispatch({ type: DropdownStateUpdate.Close })}
        transitionProps={{ transition: 'fade-up' }}
        {...props}
      >
        <Popover.Target
          onClick={handleTargetClick}
          variant={state.isFrozen ? `active-${target.props?.variant || 'subtle'}` : target.props?.variant || 'subtle'}
          ref={setTargetClick}
          >
          {target}
        </Popover.Target>
        <Popover.Dropdown p={'xxs'} ref={setDropdownClick}>
          {children}
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
})

NewDropdown.propTypes = {
  items: PropTypes.object,
  target: PropTypes.node,
  disableHoverOpen: PropTypes.bool,
  mah: PropTypes.number,
  width: PropTypes.string,
  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
  row: PropTypes.object,
  children: PropTypes.node
}
