import React, { useState } from 'react'
import { Accordion, Badge, Grid, Space, Text, Title } from '@mantine/core'
import * as styles from './AccountTabs.module.scss'
import { IconMail, IconMessage, IconDeviceWatchCode } from '@tabler/icons-react'
import EmailTwoFactorAuthentication from './EmailTwoFactorAuthentication'
import TextTwoFactorAuthentication from './TextTwoFactorAuthentication'
import TotpTwoFactorAuthentication from './TotpTwoFactorAuthentication';
import PropTypes from 'prop-types'

export default function TwoFactorAuthentication ({ account, apiBasePath }) {
  const [type, setType] = useState(() => {
    if (account.email_auth_enabled) {
      return 'email'
    } else if (account.text_auth_enabled) {
      return 'text'
    } else if (account.totp_auth_enabled) {
      return 'totp'
    }

    return null
  })

  return (
    <Grid className={styles.grid}>
      <Grid.Col span={2}>
        <Title order={4}>Two-Factor Authentication</Title>
      </Grid.Col>
      <Grid.Col span={4}>
        {type === 'email' && <Badge color='green.6' size='lg'>Email Enabled</Badge>}
        {type === 'text' && <Badge color='green.6' size='lg'>SMS Enabled</Badge>}
        {type === 'totp' && <Badge color='green.6' size='lg'>TOTP Enabled</Badge>}
        {type === null && <Badge color='gray' size='lg'>None enabled</Badge>}
        <Space h='md'/>
        <Accordion>
          <Accordion.Item value={'text'}>
            <Accordion.Control icon={<IconMessage size={22} color='blue'/>}><Text>SMS</Text></Accordion.Control>
            <Accordion.Panel>
              <TextTwoFactorAuthentication account={account} setType={setType} apiBasePath={apiBasePath}/>
            </Accordion.Panel>
          </Accordion.Item>

          {type !== 'email' &&
            <Accordion.Item value={'email'}>
              <Accordion.Control icon={<IconMail size={22} color='green'/>}><Text>Email</Text></Accordion.Control>
              <Accordion.Panel>
                <EmailTwoFactorAuthentication setType={setType} apiBasePath={apiBasePath}/>
              </Accordion.Panel>
            </Accordion.Item>
          }

          {type !== 'totp' &&
            <Accordion.Item value={'totp'}>
              <Accordion.Control icon={<IconDeviceWatchCode size={22} color='blue'/>}><Text>TOTP</Text></Accordion.Control>
              <Accordion.Panel>
                <TotpTwoFactorAuthentication setType={setType} apiBasePath={apiBasePath}/>
              </Accordion.Panel>
            </Accordion.Item>
          }
        </Accordion>
      </Grid.Col>
    </Grid>
  )
}

TwoFactorAuthentication.propTypes = {
  account: PropTypes.shape({
    email_auth_enabled: PropTypes.bool,
    text_auth_enabled: PropTypes.bool,
    totp_auth_enabled: PropTypes.bool
  }),
  apiBasePath: PropTypes.string
}
