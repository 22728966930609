/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { Text } from '@mantine/core'
import { IconAffiliate, IconClipboardList, IconFileExport, IconInfoCircle, IconMapPin, IconPencil, IconReportAnalytics, IconTableShortcut, IconTrendingUp } from '@tabler/icons-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { formatToOptions } from '../../js/util/DataUtil'
import { ClickContainer } from '../core/ReactTable/ReactTable'
import * as tableStyles from '../core/ReactTable/ReactTable.module.scss'
import axios from 'axios';
import {
  getLocations,
  getSkillBuilderFacilitators,
  getSkillBuilderFacilitatorsForLocation,
  getSkillBuilderJobsForClient
} from '../../js/api/skillbuilder_repository';
import { getOrganizationLocations, getOrganizations } from '../../js/api/organization_repository';

export function useSkillbuilderCycleColumns () {
  return useMemo(
    () => [
      {
        Header: <Text>Organization</Text>,
        id: 'organization',
        accessor: 'location.organization',
        Cell: ({ cell: { value } }) => {
          return <Text>{value.name}</Text>
        },
        filterable: true,
        sortable: true,
        hideable: true
      },
      {
        Header: <Text>Location</Text>,
        id: 'location',
        accessor: 'location',
        Cell: ({ cell: { value } }) => {
          return <Text>{value.name}</Text>
        },
        filterable: true,
        sortable: true,
        hideable: true
      },
      {
        Header: <Text>Specific Job</Text>,
        id: 'specific_job',
        accessor: 'specific_job',
        Cell: ({ cell: { value } }) => {
          return <Text>{value}</Text>
        },
        filterable: true,
        sortable: true,
        hideable: true
      },
      {
        Header: <Text>Cycle Name</Text>,
        id: 'name',
        accessor: 'name',
        Cell: ({ cell: { value } }) => {
          return <Text>{value}</Text>
        },
        filterable: true,
        sortable: true
      },
      {
        id: 'candidates',
        accessor: 'candidates',
        sortable: false,
        Header: <Text className={[tableStyles.centeredCell]}># of Candidates</Text>,
        Cell: ({ cell: { value, column } }) => {
          return (
            <ClickContainer>
              <Text className={[tableStyles.centeredCell]}>{value.length}</Text>
            </ClickContainer>
          )
        },
        hideable: true
      },
      {
        id: 'latest_candidate',
        sortable: false,
        Header: <Text className={[tableStyles.centeredCell]}>Latest Candidate</Text>,
        Cell: ({ cell: { row, column } }) => {
          if (row.original.candidates.length === 0) return <ClickContainer><Text className={[tableStyles.centeredCell]}>No candidates</Text></ClickContainer>
          const mostRecent = row.original.candidates.reduce((a, b) => new Date(a.created) > new Date(b.created) ? a : b)
          return (mostRecent.user
            ? (
            <ClickContainer>
              <Text className={[tableStyles.centeredCell]}>{`${mostRecent.user.account.first_name} ${mostRecent.user.account.last_name}`}</Text>
              <Text className={[tableStyles.centeredCell]}>{`Date created: ${new Date(mostRecent.created).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}`}</Text>
            </ClickContainer>
              )
            : '')
        },
        hideable: true
      },
      {
        Header: <Text className={[tableStyles.centeredCell]}>Assessments</Text>,
        id: 'assessments',
        accessor: 'assessments',
        Cell: ({ cell: { value } }) => {
          return <ClickContainer align='flex-start'>{value.map((assessment, index) => <Text key={`assessment-${index}`}>{assessment.assessment.name}</Text>)}</ClickContainer>
        },
        sortable: false,
        hideable: true

      }

    ], []
  )
}

export function useSkillbuilderCycleFilters (hasAdminAccess) {
  const [organizationsOptions, setOrganizationsOptions] = useState([]) // TODO refactor these queries into RTK
  const [locationsOptions, setLocationsOptions] = useState([])
  useEffect(() => {
    if (hasAdminAccess) {
      getOrganizations({ limit: 0 }).then(collection => setOrganizationsOptions(formatToOptions(collection.items)))
    }
  }, [hasAdminAccess])

  return useMemo(
    () => {
      const STATUS_OPTIONS = [
        { value: '1', label: 'Open' },
        { value: '0', label: 'Closed' }
      ]

      return [
        {
          id: 'active',
          label: 'Status',
          leftSection: <IconTrendingUp />,
          options: STATUS_OPTIONS
        },
        {
          id: 'organization',
          label: 'Organization',
          leftSection: <IconAffiliate />,
          options: organizationsOptions,
          metadata: {
            isGranted: hasAdminAccess
          },
          onUpdate: (id) => {
            if (id) {
              getLocations({ limit: 0, order_by: 'name', organization: id })
                .then(locationsCollection => {
                  setLocationsOptions(formatToOptions(locationsCollection.items)) // TODO refactor into RTK
                })
            } else {
              setLocationsOptions([])
            }
          },
          resets: ['location']
        },
        {
          id: 'location',
          label: 'Location',
          leftSection: <IconMapPin />,
          options: locationsOptions,
          metadata: {
            isGranted: hasAdminAccess
          }
        }
      ].filter(item => item.metadata?.isGranted !== false)
    }, [hasAdminAccess, organizationsOptions, locationsOptions]
  )
}

export function useSkillbuilderCycleActions (hasAdminAccess) {
  return useMemo(
    () => {
      return [
        {
          label: 'Program Level Reports',
          leftSection: <IconReportAnalytics />,
          href: (id) => `/testenv/results/${id}/compavg`
        },
        {
          label: 'Candidate Level Reports',
          leftSection: <IconClipboardList />,
          href: (id) => `/testenv/jobs/${id}/results`
        },
        {
          label: 'Exports',
          leftSection: <IconFileExport />,
          href: (id) => `/testenv/exports/${id}`
        },
        {
          label: 'Edit',
          leftSection: <IconPencil />,
          href: (id) => `/hire/report-results/${id}/edit`,
          metadata: {
            isGranted: hasAdminAccess
          }
        },
        // {
        //   label: ' Delete',
        //   leftSection: <IconTrash />,
        //   onClick: (id) => setCycleToDelete(id),
        //   metadata: {
        //     isGranted: hasAdminAccess
        //   }
        // },
        {
          label: 'Details',
          leftSection: <IconInfoCircle />,
          href: (id) => `/cycles/${id}/competencyView`,
          metadata: {
            isGranted: hasAdminAccess
          }
        }
      ].filter(item => item.metadata?.isGranted !== false)
    }, [hasAdminAccess]
  )
}

export function useSkillbuilderCycleTableActions (hasAdminAccess) {
  return useMemo(
    () => {
      return [
        {
          label: 'Custom Export',
          leftSection: <IconTableShortcut />,
          href: '/testenv/exports/sbExport',
          metadata: {
            isGranted: !hasAdminAccess
          }
        }
      ].filter(item => item.metadata?.isGranted !== false)
    }, [hasAdminAccess]
  )
}

export function useOrganizationLocations (clientId) {
  const [locations, setLocations] = useState([])

  useEffect(() => {
    if (clientId === null) {
      setLocations([])
    } else {
      const cancelToken = axios.CancelToken
      const cancelSource = cancelToken.source()
      getOrganizationLocations(clientId, 0, cancelSource.token)
        .then(collection => { if (collection) setLocations(collection.items) })

      return () => cancelSource.cancel()
    }
  }, [clientId])

  return locations
}

export function useSkillBuilderJobs (clientId) {
  const [jobs, setJobs] = useState([])
  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    getSkillBuilderJobsForClient(clientId, cancelSource.token)
      .then(collection => { console.debug('Got jobs response:', collection); if (collection) setJobs(collection.items) })

    return () => cancelSource.cancel()
  }, [clientId, setJobs])

  return jobs
}

export function useSkillBuilderFacilitators (clientId, locations = null) {
  const [facilitators, setFacilitators] = useState([])
  const activeQueryId = useRef(0)
  const [querying, setQuerying] = useState(false)

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const cancelSource = cancelToken.source()
    activeQueryId.current = activeQueryId.current + 1
    const sameActiveQueryId = activeQueryId.current
    if (locations && locations.length) {
      const theseFacilitators = new Map()
      const seenLocationResults = new Set()
      setQuerying(true)
      for (const locationId of locations) {
        getSkillBuilderFacilitatorsForLocation(locationId, cancelSource.token)
          .then(elements => {
            if (elements) {
              for (const element of Object.values(elements)) {
                theseFacilitators.set(element.id, element)
              }
            }
            if (elements) seenLocationResults.add(locationId);
            if (seenLocationResults.size === locations.length) {
              console.debug('Was last location result - updating:', locationId, seenLocationResults, locations, theseFacilitators)
              setFacilitators([...theseFacilitators.values()])
              if (activeQueryId.current === sameActiveQueryId) {
                setQuerying(false)
              } else {
                console.debug('Active query changed - not removing querying state.', activeQueryId.current, sameActiveQueryId, locations)
              }
            }
          })
      }
    } else {
      setQuerying(true)
      getSkillBuilderFacilitators(clientId, cancelSource.token)
        .then(elements => {
          console.debug('Got facilitator no-location response:', elements)
          if (elements) {
            setFacilitators([...Object.values(elements)])
            if (activeQueryId.current === sameActiveQueryId) {
              setQuerying(false)
            } else {
              console.debug('Active query changed - not removing querying state.', activeQueryId.current, sameActiveQueryId)
            }
          }
        })
    }

    return () => cancelSource.cancel()
  }, [clientId, locations, setQuerying, setFacilitators])

  return [facilitators, querying]
}
